.documentation {
  color: #fff;
  background-color: #4f4898;
  padding: 50px 150px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 30px 20px 10px;
    justify-content: center;
  }

  button {
    width: max-content;
    cursor: pointer;
    color: #fff;
    background-color: #4f4898;
    font-weight: 700;
    font-size: 36px;
    letter-spacing: 20px;
    padding: 10px 20px;
    border: 2px solid #fff;
    border-radius: 15px;

    @media (max-width: 768px) {
      width: 100%;
      font-size: 16px;
      letter-spacing: 10px;
    }

    img {
      width: 30px;
      position: relative;
      top: 5px;
      transition: all 0.3s ease;

      @media (max-width: 768px) {
        width: 15px;
      }
    }
  }
  ul {
    margin-top: 20px;
    margin-left: 20px;
    transition: 0.5s;
    height: 0;
    overflow: hidden;
    li a {
      cursor: pointer;
      display: block;
      margin-bottom: 10px;
      font-weight: 400;
      transition: font-weight 0.3s ease;

      @media (min-width: 1200px) {
        font-size: 25px;
        line-height: 32px;
      }

      &:hover {
        text-shadow: 0 0 1px white, 0 0px 1px white, 0 0px 1px white,
          0 0px 1px white, 0 0px 1px white;
      }
    }
  }
}
