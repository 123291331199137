.colorWrapper {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;

  // &::before {
  //   content: attr(data-title);
  //   position: absolute;
  //   left: calc(50% - 600px);
  //   top: 50%;
  //   transform: translateY(-50%) translateX(-50%) rotate(-90deg);

  //   font-size: 62px;
  //   color: white;
  //   white-space: nowrap;
  // }
  // background-color: red;

  // &:first-child {
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 25%;

    width: 50%;
    height: 2px;

    background: rgba(0, 0, 0, 0.25);

    @media (max-width: 1000px) {
      display: none;
    }
  }
  // }

  // &:nth-of-type(2) {
  //   padding-top: 180px;
  // }

  // &:nth-of-type(4n) {
  //   &::before {
  //     color: #50535a;
  //   }
  // }

  // &:nth-of-type(4n + 1) {
  //   &::after {
  //     background-color: #f0e400;
  //   }
  // }

  // &:last-of-type {
  //   &::after {
  //     display: none;
  //   }
  // }

  // &::after {
  //   content: "";
  //   position: absolute;
  //   bottom: 0px;
  //   left: 25%;

  //   width: 50%;
  //   height: 2px;

  //   background: rgba(0, 0, 0, 0.25);

  //   // -webkit-mask: url("data:image/svg+xml,%3Csvg width='15' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 6V0L7.5 4.5 0 0v6l7.5 4.5L15 6Zm0 15.5v-6L7.5 20 0 15.5v6L7.5 26l7.5-4.5ZM15 37v-6l-7.5 4.5L0 31v6l7.5 4.5L15 37Z' fill='black'/%3E%3C/svg%3E")
  //   //   no-repeat 50% 50%;
  //   // mask: url("data:image/svg+xml,%3Csvg width='15' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 6V0L7.5 4.5 0 0v6l7.5 4.5L15 6Zm0 15.5v-6L7.5 20 0 15.5v6L7.5 26l7.5-4.5ZM15 37v-6l-7.5 4.5L0 31v6l7.5 4.5L15 37Z' fill='black'/%3E%3C/svg%3E")
  //   //   no-repeat 50% 50%;
  //   // -webkit-mask-size: contain;
  //   // mask-size: contain;
  //   // background-color: #2ca99b;

  //   // animation: up-down 2s ease-in-out infinite, opacity 2s ease-in-out infinite;

  //   @media (max-width: 1000px) {
  //     // display: none;
  //   }
  // }

  &__mobile {
    &::before {
      // left: calc(11vw - 12px);
      left: 10vw;
      // left: -5%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%) rotate(-90deg);
      font-size: 44px;

      // outline: 20px solid red;
    }

    &:nth-of-type(3) {
      padding-top: 150px !important;
      @media (max-width: 768px) {
        padding: 0 !important;
      }
      // outline: 20px solid red;
    }
  }
}

// @keyframes up-down {
//   0% {
//     transform: translateY(-10px);
//   }
//   100% {
//     transform: translateY(10px);
//   }
// }

// @keyframes opacity {
//   0% {
//     opacity: 0;
//   }
//   70% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }
