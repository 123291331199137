.clusterArenda {
  padding-top: 100px;
  background-color: black;
  color: white;
  padding-left: 150px;
  padding-right: 150px;

  @media (max-width: 768px) {
    padding-left: 65px;
    padding-bottom: 50px;
    padding-right: 20px;
  }
}
.items {
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.title {
  text-transform: uppercase;
  margin: 0 auto 50px;
  width: max-content;
  border-radius: 15px;
  padding: 15px;
  border: 2px solid #fff;
  font-weight: 700;
  font-size: 36px;

  @media (max-width: 1350px) {
    font-size: 26px;
  }

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.successTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
}

.item {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;

  img {
    width: 100%;
    height: 300px;
    border-radius: 20px;
    object-fit: cover;
    @media (max-width: 600px) {
      height: 200px;
    }
  }
}
.first {
  width: 500px;

  @media (max-width: 1150px) {
    width: 300px;
    margin: 0 auto;
  }

  @media (max-width: 600px) {
    width: 250px;
  }
}
.second {
  display: flex;
  flex-direction: column;
}

.secondTitle {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 20px;
}

.list {
  flex: 1 0 auto;

  list-style-type: none;
  font-size: 20px;
  li {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.tags {
  display: flex;
  row-gap: 20px;
  column-gap: 10px;
  flex-wrap: wrap;
}

.tag {
  border-radius: 10px;
  padding: 5px;
  border: 2px solid #fff;
  font-size: 20px;
}

.third {
  justify-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.firstButton {
  width: 340px;
  border: none;
  font-weight: 500;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px 20px;
  background: linear-gradient(180deg, #fff 4.26%, rgba(0, 0, 0, 0.25) 199.66%, rgba(0, 0, 0, 0.25) 227.61%);
}
.secondButton {
  width: max-content;

  border: none;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #d6338a;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.priceWrapper {
  width: 300px;
  border-radius: 20px;
  padding: 20px;
  background: linear-gradient(177deg, rgba(255, 255, 255, 0.38) 3.61%, rgba(255, 255, 255, 0) 104.83%);
}

.price {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.times {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
}

.priceBig {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  b {
    font-size: 40px;
  }
}

.inputButtons {
  margin-top: 200px;
  display: flex;
  justify-content: space-between;
}
.greenButton {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 20px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-size: 20px;
  text-transform: uppercase;
  background: linear-gradient(
    174deg,
    rgba(178, 178, 178, 0.9) -88.2%,
    rgba(130, 130, 130, 0.9) 75.89%,
    rgba(130, 130, 130, 0) 215.45%
  );

  svg {
    transition: all 0.2s ease-in;
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
  }
}

.greenButtonActive {
  background: linear-gradient(180deg, rgba(42, 168, 154, 0.69) -34.57%, rgba(42, 168, 154, 0) 147.15%);
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
  }
}

.grayButton {
  cursor: pointer;
  border-radius: 10px;
  padding: 10px 20px;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-size: 20px;
  text-transform: uppercase;
  background: linear-gradient(
    174deg,
    rgba(178, 178, 178, 0.9) -88.2%,
    rgba(130, 130, 130, 0.9) 75.89%,
    rgba(130, 130, 130, 0) 215.45%
  );

  svg {
    transition: all 0.2s ease-in;
    width: 20px;
    height: 20px;
    transform: rotate(-90deg);
  }
}

.grayButtonActive {
  background: linear-gradient(180deg, rgba(79, 76, 147, 0.9) -34.57%, rgba(79, 76, 147, 0) 147.15%);
  svg {
    width: 20px;
    height: 20px;
    transform: rotate(90deg);
  }
}

.tovars {
  margin-top: 100px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 50px;
  margin-bottom: 100px;
  transition: 0.3s ease-in;
  height: 0;
  overflow: hidden;
}

.tovar {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(177deg, rgba(255, 255, 255, 0.38) 3.61%, rgba(255, 255, 255, 0) 104.83%);
  img {
    object-fit: cover;
    border-radius: 20px;
    width: 100%;
    aspect-ratio: 1/1;
  }
}
.tovarName {
  font-size: 20px;
}
.tovarCount {
  font-size: 18px;
  font-weight: 300;
}

.docs {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding-bottom: 100px;
}
.doc {
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 20px;
  border-radius: 20px;
  background: linear-gradient(177deg, rgba(255, 255, 255, 0.38) 3.61%, rgba(255, 255, 255, 0) 104.83%);

  svg {
    width: 90px;
    height: 90px;
  }
}

.docName {
  text-align: center;
  font-size: 14px;
  flex: 1 1 auto;
}
.download {
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 300;
}
@media (max-width: 1350px) {
  .item {
    grid-template-columns: repeat(2, 1fr);
  }
  .tovars {
    grid-template-columns: repeat(3, 1fr);
  }
  .inputButtons {
    flex-direction: column;
    gap: 20px;
  }
}

.card {
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  padding: 40px;
  background: linear-gradient(226deg, rgba(79, 76, 147, 0.9) 13.5%, rgba(79, 76, 147, 0) 132.03%);

  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 120%;
    width: 40%;
    object-fit: cover;
    filter: grayscale(1);
    transform: scale(1.5) rotate(8deg);
  }
}

.cardText {
  width: 40%;
  display: flex;
  flex-direction: column;
  text-wrap: balance;
  gap: 20px;
}

.cardTitle {
  font-size: 30px;
  font-weight: 700;
}

.cardDescription {
  font-size: 20px;
  font-weight: 300;
}

.cardButton {
  width: max-content;
  border: none;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background: linear-gradient(132deg, #d6338a -13.39%, rgba(214, 51, 138, 0.37) 83.67%);
}

.item:nth-of-type(3n) {
  .secondButton {
    background: linear-gradient(226deg, rgba(79, 76, 147, 0.9) 13.5%, rgba(79, 76, 147, 0) 132.03%);
  }
  svg {
    color: rgb(79, 76, 147);
  }
}
.item:nth-of-type(3n + 1) {
  .secondButton {
    background: linear-gradient(226deg, rgba(42, 168, 154, 0.9) 13.5%, rgba(79, 76, 147, 0) 132.03%);
  }
  svg {
    color: rgb(42, 168, 154);
  }
}
.item:nth-of-type(3n + 2) {
  .secondButton {
    background: linear-gradient(226deg, rgba(214, 51, 138, 0.9) 13.5%, rgba(79, 76, 147, 0) 132.03%);
  }
  svg {
    color: rgb(214, 51, 138);
  }
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 200000;

  height: 100vh;
  width: 100vw;

  overflow-y: auto;
  padding: 50px 0;
  pointer-events: auto;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.6);
  box-sizing: border-box;

  @media (max-width: 450px) and (max-height: 700px) {
    padding-top: 200px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: min-content;
  margin: auto 0;
  position: relative;
  z-index: 200001;
  border-radius: 20px;
  color: black;
  gap: 10px;
  background: linear-gradient(
    196deg,
    rgba(255, 255, 255, 0.9) -18.74%,
    rgba(255, 255, 255, 0.83) 41.7%,
    rgba(255, 255, 255, 0) 142.18%
  );
  padding: 20px;
  max-width: 650px;

  & > div {
    width: 100%;
  }
}

.titleBron {
  font-size: 26px;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: black;
  text-transform: uppercase;
  color: #fff;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 12px;
  }
}
.name {
  text-align: center;
  text-decoration: underline;
}
.input {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  @media (max-width: 900px) {
    font-size: 14px;
  }

  textarea {
    resize: none;
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 5px;
  }

  input {
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 5px;
  }

  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    bottom: 10px;
    width: 20px;
    height: 20px;
  }
}
.inputText {
  display: flex;
  flex-direction: column;
  gap: 5px;
  textarea {
    resize: none;
    padding: 10px 20px;
    border: 1px solid black;
    border-radius: 5px;
  }
}

.bronButton {
  margin: 0 auto;
  width: max-content;
  border: none;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #4f4c93;

  &:disabled {
    background-color: #4f4c9377;
  }
}
.closeButton {
  margin: 0 auto;
  width: max-content;
  border: none;
  color: black;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: transparent;
}

.contentCalendar {
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(359deg, #000 -89.3%, rgba(0, 0, 0, 0.1) 60.9%, rgba(0, 0, 0, 0.82) 188.39%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff;
  width: 300px;
}

.contentCalendar2 {
  padding: 20px;
  border-radius: 20px;
  background: linear-gradient(359deg, #000 -89.3%, rgba(0, 0, 0, 0.1) 60.9%, rgba(0, 0, 0, 0.82) 188.39%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff;
  width: 50%;

  @media (max-width: 1500px) {
    width: 80%;
  }

  @media (max-width: 1500px) {
    width: 90%;
  }
}
.timeWrapper {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.calendarTime {
  text-align: center;
}
.inputCalendar {
  color: white;
  width: 80px;
  border-radius: 5px;
  border: 1px solid white;
  background-color: transparent;
}
.blockWrapper {
  display: flex;
  gap: 10px;
}

.block {
  background: linear-gradient(180deg, #2aa89a 0%, rgba(42, 168, 154, 0) 100%);
  min-width: 20px;
  height: 20px;
}
.block2 {
  background: linear-gradient(180deg, #d6338a 0%, rgba(214, 51, 138, 0) 138.57%);
  min-width: 20px;
  height: 20px;
}
.block3 {
  background: linear-gradient(180deg, #4f4c93 0%, rgba(214, 51, 138, 0) 138.57%);
  min-width: 20px;
  height: 20px;
}
.linkWithUs {
  font-weight: 300;
  font-size: 12px;
  text-align: center;
}
.calendarWrapper {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calendarWrapper2 {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.titleCalendar {
  text-align: center;
  margin-bottom: 20px;
}

.calendarButton {
  margin: 0 auto;
  width: max-content;
  border: none;
  color: white;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: transparent;
}
@media (max-width: 1150px) {
  .docs {
    flex-wrap: wrap;
  }
}
@media (max-width: 1000px) {
  .tovars {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 800px) {
  .item {
    grid-template-columns: repeat(1, 1fr);
  }
  .tag {
    font-size: 12px;
  }
  .tags {
    row-gap: 10px;
  }
  .third {
    justify-self: unset;
  }
  .cardText {
    width: unset;
  }
}
@media (max-width: 500px) {
  .tovars {
    grid-template-columns: repeat(1, 1fr);
  }
  .firstButton {
    width: unset;
  }
  .priceWrapper {
    width: unset;
  }
}
