.PageTemplate {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.temp {
  flex: 1 0 auto;
}
