.painters {
  margin-bottom: 66px;
  margin-top: 150px;

  &__mobile {
    margin-top: 100px;
  }

  a {
    text-decoration: none;
  }
}

.title {
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  color: #50535a;

  margin-bottom: 35px;

  &__mobile {
    font-size: 18px;
    line-height: 23px;
  }
}

.painter {
  display: grid;
  grid-template-columns: 227px 1fr;
  column-gap: 50px;

  padding: 23px 0;

  position: relative;
  overflow: hidden;

  width: 100% !important;
  max-width: 100%;
  box-sizing: border-box;

  text-decoration: none;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 50%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 67%
  );
  background-size: 100% 300%;
  background-position-y: top;

  transition: background-position-y 0.2s cubic-bezier(0.71, 0.27, 1, 0.64);

  &:hover {
    // background-color: black;
    background-position-y: bottom;

    .painter_inner {
      padding-left: 20px;
    }

    .painter_name {
      color: white;
    }

    .painter_nick {
      color: #d6358c;
    }
  }

  &__mobile {
    grid-template-columns: 150px 1fr;
    column-gap: 10px;
    padding: 13px 0;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: black;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  & > a {
    grid-area: 1/1/2/2;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    text-decoration: none;

    &:hover {
      h3 {
        color: #d6358c;
      }
    }
  }
}

.painter_inner {
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: all 0.3s ease;
}

.painter_nick {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #50535a;
  transition: color 0.3s ease;

  &__mobile {
    font-size: 17px;
    line-height: 21px;
  }
}

.painter_name {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #50535a;

  padding-top: 10px;

  &__mobile {
    font-size: 14px;
    line-height: 100%;
    padding-top: 8px;
  }
}

.painter_list {
  grid-area: 1/2/2/3;
  display: flex;
  align-items: center;
  max-width: 900px !important;
  // min-height: 100%;
}

.picture {
  width: 74px;
  height: 74px;
  cursor: pointer;

  // outline: 0rem solid white;
  // outline-offset: 0;
  // transition: all .3s ease;

  &:hover {
    // outline: 2px solid yellow;
    // outline-offset: -2px;
    // box-shadow: 0 0 5px 2px yellow;
    // position: relative;
    // z-index: 19999;
    // transform: translateY(-10%) scale(1.1);
    // width: 90px;
    // height: 90px;
  }

  &__mobile {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}
