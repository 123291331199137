.clusterRezidents {
  background-color: #000;
  color: #fff;
  padding-left: 150px;
  padding-right: 150px;

  @media (max-width: 768px) {
    padding-left: 65px;
    padding-right: 20px;
  }
}

.header {
  display: flex;
  column-gap: 200px;
  row-gap: 20px;
  flex-wrap: wrap;
}

.list {
  text-transform: uppercase;
  font-size: 26px;
  list-style-type: none;
  li {
    position: relative;
    width: max-content;
    border-radius: 10px;
    padding: 0 15px;
    margin-bottom: 20px;
    text-decoration: underline;

    &:nth-of-type(n + 1) {
      background-color: #4f4c93;
    }
    &:nth-of-type(n + 2) {
      background-color: #2aa89a;
    }
    &:nth-of-type(n + 3) {
      background-color: #d6338a;
    }

    &::before {
      position: absolute;
      top: 12px;
      left: 5px;
      content: '';
      display: block;
      height: 6px;
      width: 6px;
      border-radius: 100%;
      background-color: #fff;
    }
  }
}

.title {
  text-transform: uppercase;
  font-size: 36px;
}

.headerTitle {
  width: max-content;
  margin: 50px auto;
  text-align: center;
  font-size: 26px;
  border-radius: 10px;
  padding: 0 15px;
  background-color: #fff;
  color: #000;
}

.images {
  display: flex;
  gap: 30px;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.image {
  position: relative;
  border-radius: 20px;
  min-width: 300px;
  width: 30%;
  height: 250px;

  &:nth-of-type(n + 1) {
    background: linear-gradient(180deg, #d6338a 0%, rgba(0, 0, 0, 1) 100%);

    .imageTitle {
      background-color: #2aa89a;
    }
  }
  &:nth-of-type(n + 2) {
    background: linear-gradient(180deg, #4f4c93 0%, rgba(0, 0, 0, 1) 100%);

    .imageTitle {
      background-color: #d6338a;
    }
  }
  &:nth-of-type(n + 3) {
    background: linear-gradient(180deg, #2aa89a 0%, rgba(0, 0, 0, 1) 100%);

    .imageTitle {
      background-color: #4f4c93;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .imageText {
    position: absolute;
    top: 80px;
    left: 20px;
  }

  .imageTitle {
    text-transform: uppercase;
    width: max-content;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 5px;
    background-color: inherit;
  }
}

.howWrapper {
  display: flex;
}

.how {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  letter-spacing: 0.5rem;
  font-size: 60px;
  font-weight: 300;

  .bold {
    font-weight: 700;
    margin-bottom: 50px;
  }

  .questionSvg {
    height: 500px;
  }
}

.rezidentSvg {
  min-height: 200px;
  height: 100%;
}

@media (max-width: 1630px) {
  .image {
    .imageTitle {
      font-size: 20px;
    }
  }
}

@media (max-width: 1400px) {
  .title {
    font-size: 26px;
  }
  .list {
    font-size: 20px;
  }
  .how {
    letter-spacing: 0.5rem;
    font-size: 40px;

    .questionSvg {
      height: 300px;
    }
  }
  .rezidentSvg {
    width: 500px;
  }
}

@media (max-width: 1100px) {
  .howWrapper {
    flex-direction: column;
  }
}

@media (max-width: 900px) {
  .headerTitle {
    width: unset;
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .title {
    font-size: 20px;
  }
  .list {
    font-size: 13px;

    li {
      &::before {
        top: 6px;
        left: 5px;
      }
    }
  }
  .how {
    letter-spacing: 0.5rem;
    font-size: 30px;
    .bold {
      margin-bottom: 10px;
    }
    .questionSvg {
      height: 100px;
    }
  }
  .rezidentSvg {
    width: unset;
    height: 100%;
  }
}
