.content {
  display: flex;
  margin-right: 20px;
}

.leftColumn {
  width: 50%;
}

.rightColumn {
  width: 50%;
}

.title {
  font-size: 30px;
  text-transform: uppercase;
  margin: 20px;
}

.list {
  font-size: 26px;
  > li {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  svg {
    height: 30px;
    width: 30px;
  }
}

.list2 {
  font-size: 26px;
  > li {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  svg {
    height: 30px;
    width: 30px;
  }
}

.innerList {
  font-size: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: white;
  list-style-type: disc;
  padding-left: 60px;
  font-weight: 300;
}

.stavka {
  margin-top: 50px;
  border-radius: 10px;
  font-size: 20px;
  padding: 20px;
  width: max-content;
  display: flex;
  align-items: center;
  gap: 40px;
  background: linear-gradient(
    125deg,
    #2aa89a -25.9%,
    rgba(42, 168, 154, 0) 86.99%
  );
}
.stavkaText {
  text-transform: uppercase;
}

.cards {
  display: flex;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.36);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 0) 122.49%
  );
  box-shadow: 0px 3.66071px 53.08032px 0px rgba(0, 0, 0, 0.25) inset;
}

.cardTitle,
.cardTitle2 {
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 20px;
  font-size: 30px;
  text-transform: uppercase;
}

.cardTitle {
  background-color: #4f4c93;
}
.cardTitle2 {
  background-color: #d6338a;
}

.cardPercent {
  padding: 0 20px;
  font-size: 26px;
  line-height: 1.2em;
  min-height: 4.8em;
}

.cardText {
  font-size: 20px;
  font-weight: 300;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin: 0 20px;
  padding: 20px 0;
  line-height: 1.2em;
  min-height: 3.6em;
}

.cardText2 {
  font-size: 20px;
  font-weight: 300;
  padding: 0 20px;
  margin-bottom: 20px;
}

@media (max-width: 1250px) {
  .content {
    flex-direction: column;
    gap: 30px;
  }

  .leftColumn {
    width: 100%;
  }

  .rightColumn {
    width: 100%;
    margin-bottom: 20px;
  }
  .list,
  .list2 {
    font-size: 16px;
    ul > li {
      font-size: 14px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .title {
    font-size: 20px;
  }
  .stavka {
    font-size: 16px;
  }

  .cardTitle,
  .cardTitle2 {
    font-size: 20px;
  }

  .cardPercent {
    font-size: 16px;
  }

  .cardText {
    font-size: 16px;
  }

  .cardText2 {
    font-size: 16px;
  }
}
@media (max-width: 700px) {
  .cards {
    flex-direction: column;
  }
}
