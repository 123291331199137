.tariff {
  background-color: #fff;
  padding: 40px 150px 100px;

  @media (max-width: 772px) {
    padding: 20px 50px 20px;
  }
  > div {
    display: flex;
    justify-content: center;
    gap: 50px;
    position: relative;
    margin-bottom: 50px;

    @media (max-width: 1072px) {
      flex-wrap: wrap;
      gap: 100px;
    }

    > p {
      position: absolute;
      top: 252px;
      left: -124px;
      transform: rotate(270deg);
      font-size: 46px;
      border: 2px solid #000;
      border-radius: 15px;
      padding: 10px;
      height: max-content;
      box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.31);

      @media (max-width: 1650px) {
        left: -224px;
      }
      @media (max-width: 800px) {
        left: -80px;
        font-size: 36px;
      }
      @media (max-width: 663px) {
        position: static;
        transform: rotate(0deg);
        margin-bottom: -70px;
      }
    }
    div {
      position: relative;
      div {
        width: 300px;
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 15px;
        background-color: #d9d9d9;
        padding: 30px 30px 60px;
        box-shadow: 12px 12px 12px 0px rgba(0, 0, 0, 0.31);
        @media (max-width: 1450px) {
          padding: 15px 15px 30px;
          width: 200px;
        }
        @media (max-width: 867px) {
          width: 300px;
        }

        p {
          font-size: 36px;

          @media (max-width: 1450px) {
            font-size: 26px;
          }
        }
        span {
          font-weight: 700;
          font-size: 66px;

          @media (max-width: 1450px) {
            font-size: 46px;
          }
          ~ span {
            text-align: end;
            font-weight: 300;
            font-size: 16px;
          }
        }

        ul {
          margin-left: 30px;

          @media (max-width: 1450px) {
            font-size: 14px;
          }
          li {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
    div:nth-of-type(2) {
      div:nth-of-type(1) {
        background-color: #2aa89a;
        color: #fff;
      }
    }
    div:nth-of-type(3) {
      div:nth-of-type(1) {
        background-color: #4f4898;
        color: #fff;
      }
    }
    div.border {
      position: absolute;
      top: 25px;
      right: -20px;
      background-color: transparent;
      border: 1px SOLID #fff;
      box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.31);
      width: 300px;
      height: 100%;

      @media (max-width: 1450px) {
        width: 200px;
        top: 16px;
        right: -14px;
      }
      @media (max-width: 867px) {
        width: 300px;
      }
    }
  }

  .tariffButtons {
    display: flex;
    position: relative;
    top: 120px;
    justify-content: space-evenly;
    gap: 0px;

    @media (max-width: 768px) {
      top: 50px;
    }

    .arendaButton {
      z-index: 4;
    }

    button {
      position: relative;
      z-index: 2;
      width: 500px;
      font-family: 'Geometria';
      cursor: pointer;
      border: none;
      border-radius: 15px;
      font-weight: 300;
      padding: 15px 40px;
      display: flex;
      color: #fff;
      gap: 10px;
      background-color: #000;
      font-size: 24px;
      margin-bottom: 20px;
      box-shadow: 6px 6px 6px 0px rgba(0, 0, 0, 0.31);

      @media (max-width: 867px) {
        font-size: 16px;
        padding: 15px 20px;
      }

      @media (max-width: 600px) {
        width: 300px;
      }
    }
  }
  .button1Wrapper {
    position: relative;
  }

  .dropDown,
  .dropDown1 {
    z-index: 1;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 30px;
    background-color: #fff !important;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    width: 500px;
    box-shadow: 10px 10px 36px -5px rgba(0, 0, 0, 0.45);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 0 !important;

    @media (max-width: 600px) {
      width: 300px;
    }
    .title {
      margin-top: 50px;
      margin-bottom: 0px;
      color: #000;
      font-size: 16px;
    }

    input {
      margin: 5px 20px 5px;
      padding: 10px;
      background-color: #d9d9d9;
      border: none;

      &:first-of-type {
        margin-top: 30px;
      }
      &::placeholder {
        font-family: 'Geometria';
        text-align: center;
        color: #000;
      }
    }

    p {
      margin-top: 5px;
      text-align: center;
      font-size: 10px;
      color: #000 !important;
      margin-bottom: 10px;
    }
  }

  .dropDown1 {
    z-index: 3;
  }

  button.sendButton {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 460px;
    padding: 5px;
    border-radius: 5px;
    background-color: #4f4898;

    @media (max-width: 600px) {
      width: 260px;
    }
  }

  .submitMessage {
    width: 460px;
    height: 100%;
    background-color: rgba(214, 51, 138, 0.95);
    padding: 20px;
    padding-top: 40px;

    @media (max-width: 600px) {
      width: 260px;
    }
    p {
      color: #fff !important;
      font-size: 16px !important;
      text-align: center;
      border: 1px solid #fff;
      padding: 20px;
    }

    button.close {
      display: flex;
      justify-content: center;
      text-align: center;
      background-color: transparent;
      width: 100%;
      box-shadow: none;
    }
  }
}
