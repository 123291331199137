.section {
  padding-top: 100px;
  padding-bottom: 100px;

  padding-left: 70px;
  padding-right: 70px;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.title {
  margin-bottom: 50px;
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;

  list-style: none;

  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
    column-gap: 20px;
  }
}

.item {
  flex: 1 0 45%;
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  margin-bottom: -1px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: black;
    height: 1px;
    width: 100%;
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.itemTitle {
  display: flex;
  justify-content: space-between;

  & > button {
  }
}

.button {
  font-weight: 600;
  font-size: 20px;
  line-height: 0;

  background-color: transparent;
  border: none;

  transition: rotate 0.3s ease;

  &.open {
    rotate: 45deg;
  }
}

.itemDescription {
  height: 0;
  opacity: 0;
  padding-top: 0px;

  transition: opacity 0.3s ease, padding-top 0.3s ease;

  &.open {
    height: auto;
    opacity: 1;
    padding-top: 20px;
  }
}
