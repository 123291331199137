#miniSlider {
  margin-top: 20px;

  .slick-slide img {
    @media (max-width: 1000px) {
      width: 80% !important;
    }
  }
  .slick-next::before {
    right: 27px !important;
    top: -15% !important;
    @media (max-width: 1000px) {
      right: 0px !important;
      top: 45% !important;
    }
  }
  .slick-prev::before {
    right: 10px !important;
    top: -15% !important;
    @media (max-width: 1000px) {
      right: 0px !important;
      top: 45% !important;
    }
  }
}

#promoSlider {
  .slick-slider {
    width: 100%;
    min-width: 0;
  }

  .slick-list {
    // width: 600px;
    max-width: 600px;

    @media (max-width: 1000px) {
      width: 100%;
      height: 100% !important;
    }
  }

  .slick-track {
    margin: auto 0;
    display: flex;
    align-items: center;

    max-height: 400px;

    @media (max-width: 1000px) {
      height: 100%;
      max-height: 187px;
    }
  }

  .slick-slide {
    width: 100%;
    min-width: 0;

    @media (max-width: 1000px) {
      margin: 0;
    }

    div {
      display: flex !important;
      align-items: center;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    img {
      display: block;
      margin: auto;
      width: 100%;

      @media (max-width: 1000px) {
        width: 100%;
      }
    }
  }

  .slick-next {
    position: absolute;
    right: -50px;

    @media (max-width: 1000px) {
      right: -20px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      // bottom: unset;
      right: 0;

      background-image: url("data:image/svg+xml,%3Csvg width='16' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.4 16.7 2.3 9.2l12-7.4' stroke='%23D6338A' stroke-width='2.9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      transform: translateY(-50%) rotateZ(180deg);
      width: 20px;
      height: 20px;
    }
  }

  .slick-prev {
    position: absolute;
    left: -10px;

    @media (max-width: 1000px) {
      left: -20px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      // bottom: unset;
      right: 0;

      background-image: url("data:image/svg+xml,%3Csvg width='16' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.4 16.7 2.3 9.2l12-7.4' stroke='%23D6338A' stroke-width='2.9' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
    }
  }
}
