.table {
  border-collapse: collapse;
  tr {
    text-align: center;
    vertical-align: bottom;
    height: 45px;
  }
  td {
    width: 10%;
    border: 1px solid;
  }

  .first {
    border-left: none;
  }
  .last {
    border-right: none;
  }
  .firstHead {
    border: none;
  }
}
