.nav {
  position: fixed;
  top: 50%;
  left: 30px;
  transform: translate(-45%, -50%) rotate(90deg);
  margin-top: 50px;
  z-index: 500;
  width: 80vh;
  mix-blend-mode: difference;

  @media (max-width: 768px) {
    left: -20px;
  }

  ul {
    display: flex;
    width: 100%;
    height: 100%;

    li {
      cursor: pointer;
      height: 50px;
      flex-basis: 25%;
      justify-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 768px) {
        height: 30px;
      }
      &:hover span {
        font-size: calc(10px + 1.2vh);
        bottom: -20px;
      }
      &:hover a::before {
        font-size: 30px;
        top: -12px;
      }
      &:hover a::after {
        top: 80%;
      }

      a {
        height: 100%;
        text-decoration: none;
        transform: rotate(180deg);
        position: absolute;
        text-align: center;
        color: #fff;
        width: auto;

        &::before {
          content: '■';
          position: absolute;
          left: 50%;
          top: 0;
          font-size: 0;
          transform: translate(-50%);
          color: inherit;
          transition: all 0.3s ease;
        }
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 20px;
          position: absolute;
          top: 0;
          left: 0;
        }
        &:hover::after {
          top: 80%;
        }
        &:hover::before {
          font-size: 30px;
          top: -12px;
        }
        span {
          position: relative;
          bottom: 0;
          font-size: calc(10px + 0.7vh);
          transition: all 0.3s ease;
          color: inherit;
          white-space: nowrap;
        }
      }
      &.open {
        a::before {
          font-size: 30px;
          top: -12px;
        }
        span {
          font-size: calc(10px + 1.2vh);
          bottom: -20px;
        }
      }
    }
  }
}
