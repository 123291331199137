.days {
  grid-area: 1/2/2/3;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  height: 100%;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;

    margin-right: 25px;
    padding: 0;

    position: relative;
    height: 100%;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      span {
        transform: scale(1.4);

        font-weight: 600;

        background: linear-gradient(
          180deg,
          rgba(#d6358c, 1) 30%,
          rgba(#4f4898, 1) 90%
        );

        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;

        background-blend-mode: screen;
        mix-blend-mode: screen;

        &::before {
          background: linear-gradient(
            180deg,
            rgba(#d6358c, 0.2) 30%,
            rgba(#4f4898, 0.2) 90%
          );
          filter: blur(5px);
        }
      }
    }

    span {
      display: block;

      font-weight: 400;
      font-size: 32px;
      line-height: 33px;
      color: white;

      transition: transform 0.3s ease;

      &::before {
        content: attr(data-days);
        position: absolute;
        display: block;
        left: 0;

        font-weight: 400;
        font-size: 32px;
        line-height: 33px;
        color: white;
      }
    }

    &:hover {
      span {
        transform: scale(1.4);
        font-weight: 600;

        background: linear-gradient(
          180deg,
          rgba(#d6358c, 1) 30%,
          rgba(#4f4898, 1) 90%
        );

        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;

        background-blend-mode: screen;
        mix-blend-mode: screen;

        // &::before {
        //   background: linear-gradient(
        //     180deg,
        //     rgba(#d6358c, 0.2) 30%,
        //     rgba(#4f4898, 0.2) 90%
        //   );
        //   filter: blur(5px);
        // }
      }
    }
  }

  &__mobile {
    grid-area: 2/1/3/2;

    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    // justify-content: center;
    margin-top: 20px;

    button {
      margin-right: 0;
      margin-bottom: 25px;
      align-self: center;
      justify-self: center;

      span {
        font-size: 19px;
        line-height: 19px;
        &::before {
          font-size: inherit;
          line-height: inherit;
        }
      }
    }
  }
}
