#partners {
  nav {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #fff;

      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
}
