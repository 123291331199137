.page {
  min-height: calc(100vh - 5px);
  height: calc(100vh - 5px);
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
}

.wrapper {
  padding-top: 100px;
}

.couponsTable {
  border: 1px solid #ffffff;
  border-collapse: collapse;
  padding: 5px;
  margin: 5px;

  th {
    border: 1px solid #ffffff;
    padding: 5px;
  }
  td {
    border: 1px solid #ffffff;
    padding: 5px;
  }
}

.coupons {
}
