.clusterTabs {
  min-height: 90vh;
  background-color: #000;
  color: #fff;
  padding-top: 30px;
  padding-left: 150px;
  padding-right: 150px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding-left: 65px;
    padding-right: 0px;
  }
}

.titleWrapper {
  display: flex;

  @media (max-width: 980px) {
    flex-direction: column;
    gap: 20px;
  }
}

.title1 {
  text-transform: uppercase;
  color: #f0e400;
  font-size: 26px;
}
.title2 {
  text-transform: uppercase;
  margin: 0 auto 20px;
  width: max-content;
  border-radius: 15px;
  padding: 15px;
  border: 2px solid #fff;
  font-weight: 700;
  font-size: 36px;

  @media (max-width: 1350px) {
    font-size: 26px;
  }

  @media (max-width: 768px) {
    font-size: 13px;
    margin: unset;
    margin-bottom: 20px;
  }
}

.content {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
}

.tabs {
  flex: 0 0 auto;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: auto;
  font-size: 20px;
  padding: 20px;
  display: flex;
  justify-content: space-around;
  background-color: rgba(255, 255, 255, 0.25);
  border-radius: 20px;

  @media (max-width: 768px) {
    margin-right: 20px;
  }

  .tab {
    cursor: pointer;
  }

  .tabActive {
    color: #f0e400;
    border-bottom: 2px solid #f0e400;
  }
}

@media (max-width: 1000px) {
  .tabs {
    font-size: 16px;
    justify-content: unset;
  }
}
