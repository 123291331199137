.icon {
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: unset;
    opacity: 0.6;
  }
}

.primary {
  color: #ffffff;
  background-color: #d6338a;
}

.secondary {
  color: #ffffff;
  background-color: #d6338a;
}

.sm {
  width: 2rem;
  height: 2rem;
}
.md {
  width: 2.5rem;
  height: 2.5rem;
}
.lg {
  width: 3rem;
  height: 3rem;
}
