.progressBar {
  position: absolute;
  right: 0;
  left: 0;
  height: 0.25rem;
  top: -4px;
  background-color: #d6338a;

  :hover {
    background-color: #d6338a;
    height: 0.3rem;
  }
}

.barInput {
  position: absolute;
  inset: 0px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  appearance: none;
  cursor: pointer;
  accent-color: #d6338a;

  ::before {
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0px;
    background-color: #d6338a;
    transform-origin: left;
  }

  ::after {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #d6338a;
  }
}
