html,
body {
  height: 100%;
  max-height: 100vh;
  // scrollbar-color: #458245 #714826; // «цвет ползунка» «цвет полосы
  // scrollbar-width: thin; // толщина
  // overflow-y: hidden;
}

html {
}

#root {
  overflow-y: scroll;
  overflow-x: hidden;

  height: 100%;
  /* плавный скролл до элемента */
  scroll-behavior: smooth;
  /* отступ при скролле */
  scroll-padding-top: 3rem;
  &::-webkit-scrollbar {
    display: block !important;
  }
}
::-webkit-scrollbar {
  // display: none;
  width: 5px;
  height: 5px;
  opacity: 0;
  visibility: hidden;

  &:hover {
    //  width: 100px;

    ::-webkit-scrollbar-thumb {
      outline: 3px solid green;
    }
  }
}
// ::-webkit-scrollbar-button {
//   // display: none;
// }
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #666;
  border-radius: 3px;
}
::-webkit-scrollbar-track-piece {
  background-color: rgba(black, 0.3);
}

a,
button {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

a:-webkit-any-link:focus-visible {
  outline: none;
  /* outline-offset: 1px; */
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* .slick-list {
  width: 100%;
}

.slick-track {
  display: flex;
}

.slick-slide {
  min-width: auto;
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fade {
  &.enter {
    opacity: 0;

    &-active {
      opacity: 1;
      transition: all 0.3s ease;
    }
  }

  &.exit {
    opacity: 1;
    &-active {
      opacity: 0;
      transition: all 0.3s ease;
    }
  }
}

.enter {
  opacity: 0;

  &-active {
    opacity: 1;
    transition: opacity 0.4s ease;
  }
}

.exit {
  opacity: 1;

  &-active {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
}

.fade_swipe {
  white-space: pre-wrap;

  &.enter {
    opacity: 0;
    transform: scale(0.95);

    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.3s ease;
    }
  }

  &.exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transform: translateX(500px) scale(0.95);
      transition: all 0.3s ease;
    }
  }
}

.invalid {
  border: 2px solid red;
}
