.wrapper {
  padding-top: 150px;
  h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    text-decoration: underline;

    margin-bottom: 70px;

    @media (max-width: 768px) {
      font-size: 18px;

      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    padding-top: 100px;
  }
}

.exhibitiosList {
  width: 100%;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    display: flex;

    overflow-x: scroll;
    margin-bottom: 22px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
