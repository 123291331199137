.burger {
  height: 30px;
  background-color: transparent;
  border: none;
  outline: none;

  transition: all 0.3s ease;

  position: relative;
  z-index: 5200;

  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  margin-left: 10px;
  padding-right: 0;

  span {
    display: block;
    width: 25px;
    height: 4px;

    transition: all 0.3s ease;

    &:not(:last-child) {
      margin-bottom: 4px;
    }

    &:nth-child(1) {
      background-color: #4f4898;

      transform: translateX(0) skew(0);
    }

    &:nth-child(2) {
      background-color: #d6338a;
      transform: translateX(0) skew(0);
    }
    &:nth-child(3) {
      background-color: #f0e400;
      transform: translateX(0) skew(0);
    }
  }

  &.pressed {
    transform: rotate(-60deg);
    transform-origin: 45% 55%;

    span {
      &:nth-child(1) {
        transform: translateX(2px) skew(30deg);
      }

      &:nth-child(2) {
        transform: translateX(-2px) skew(30deg);
      }
      &:nth-child(3) {
        transform: translateX(6px) skew(30deg);
      }
    }
  }
}
