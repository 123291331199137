.grid {
  position: relative;
  height: 100vh;
  width: 100vw;
}
.gridWrapper {
  overflow: hidden;
  background-color: #000;
}

.buttonCat {
  position: absolute;
  left: calc(50vw - 550px);
  bottom: 100px;
  border: none;
  background-color: transparent;
  box-shadow: 0 0 10px 0px rgba(white, 0.4);

  a {
    display: block;
    text-decoration: none;
    color: white;
    font-weight: 700;
    font-size: 19px;
    line-height: 1;
    padding: 6px 10px 8px;

    background-color: black;
    position: relative;

    span {
      position: relative;
      z-index: 2;
    }

    &::after {
      content: '';
      position: absolute;
      z-index: 0;

      left: 0;
      bottom: -6px;
      width: 100%;
      height: 6px;
      background: linear-gradient(270deg, #d6358c 0, #4f4b98 50%, #d6358c 100%);
      background-size: 400% 400%;

      -webkit-animation: gradient 20s linear infinite;
      animation: gradient 20s linear infinite;

      transition: all 0.4s ease;
    }

    &:hover {
      &::after {
        height: calc(100% + 0px);
        bottom: 0;
      }
    }
  }

  &__mobile {
    left: calc(10vw - 0px);
    bottom: 70px;

    a {
      font-size: 16px;
    }
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: -400% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 400% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: -400% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -400% 50%;
  }
}
