.backArrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #fff;
  font-weight: 500;
  font-size: 27px;
  margin-bottom: 20px;
  color: #fff;
  text-decoration: none;

  svg {
    transition: transform 0.3s ease;

    @media (max-width: 1000px) {
      rect {
        transition: fill 0.3s ease;
        fill: #ab1a68;
      }
      path {
        stroke: white;
      }
    }
  }

  &:hover {
    svg {
      transform: translateX(-10px);
    }
  }
}

.resident {
  min-height: calc(100vh - 150px);
  background-color: black;
  color: #fff;
  padding: 150px 200px;
  padding-bottom: 0;
}

.ResWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.card {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  justify-content: space-between;
  gap: 50px;
}

.first {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-self: end;
}
.logo {
  max-width: 200px;
  border-radius: 20px;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.link {
  text-decoration: underline;
  font-size: 16px;
}
.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 30px;
    height: 30px;
  }
}
.phone {
  font-size: 20px;
}
.buttonCard {
  text-align: center;
  border: none;
  padding: 10px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  background: linear-gradient(
    127deg,
    #4f4c93 21.18%,
    rgba(79, 76, 147, 0) 106.57%
  );
}

.second {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.secondTitle {
  margin: 0 auto 20px;
  border-radius: 15px;
  padding: 15px;
  border: 2px solid #fff;
  font-weight: 700;
  font-size: 36px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}
.secondTitle {
  max-width: 700px;
}

.cardTitle {
}

.cardDesc {
  flex: 1 1 auto;
}

.third {
  width: 500px;
}
.slider {
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

@media (max-width: 1500px) {
  .third {
    width: 400px;
  }
}
@media (max-width: 1275px) {
  .third {
    width: 300px;
  }
}

@media (max-width: 1350px) {
  .card {
    gap: 50px;
  }
}

@media (max-width: 1150px) {
  .resident {
    padding: 150px 50px;
  }
  .card {
    grid-template-columns: 1fr 2fr;
    column-gap: 0;
    row-gap: 50px;
  }
  .second {
    order: 1;
  }
  .first {
    order: 0;
    justify-self: unset;
  }
  .third {
    order: 2;
    width: 500px;
    margin: 0 auto;
    grid-column: 1 / 3;
    padding-bottom: 50px;
  }
  .slider {
    margin: 0 auto;
    width: 90%;
  }
}
@media (max-width: 800px) {
  .card {
    grid-template-columns: 1fr;
  }
  .third {
    grid-column: unset;
  }
  .first {
    justify-self: center;
  }
  .logo {
    margin: 0 auto;
  }
}
@media (max-width: 600px) {
  .third {
    width: 300px;
  }
}
