.text {
  color: white;
  text-align: center;
}

.slick {
  position: relative;
  z-index: 101;
  transform: translateY(8rem);
  
  @media (max-width: 900px) {
    transform: translateY(4rem);
  }
}
