#PaintersSlider {
  .slick-slider {
    width: 100%;
  }

  .slick-list {
    margin: 0;
    // width: 70vw;
    overflow: hidden;

    @media (max-width: 1000px) {
      width: 55vw;
    }
  }

  .slick-track {
    display: flex;
    min-width: 0;
    margin: 0;
    max-width: min-content !important;
    // outline: 2px solid red;
  }

  .slick-slide {
    width: 74px !important;
    height: 74px !important;

    margin-left: 50px;
    transition: all 0.3s ease;

    @media (max-width: 1000px) {
      // margin-right: 15px;

      width: 50px !important;
      height: 50px !important;

      margin-left: 15px;
    }

    &:first-child {
      margin-left: 0;

      @media (max-width: 1000px) {
      }
    }

    & > div {
      div {
        outline: 0 solid white;
        outline-offset: -2px;
        transition: all 0.3s ease;
      }
    }

    &:hover {
      & > div {
        div {
          outline: 0.2rem solid white;
          outline-offset: -0.1rem;
        }
      }
    }
  }

  .slick-arrow {
    // outline: 3px solid red;
    z-index: 100;
    height: 100%;
    width: 50px;
    opacity: 1;
    transition: opacity 0.3s ease;

    @media (max-width: 1000px) {
      width: 40px;
    }

    &::before {
      content: "";
    }

    &.slick-disabled {
      display: none !important;
    }
  }

  .slick-prev {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    );

    @media (max-width: 550px) {
      width: 30px;
    }
  }

  .slick-next {
    right: calc(100% - 825px);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 1) 100%
    );

    @media (max-width: 1000px) {
      right: calc(0% + 0px);
    }

    @media (max-width: 550px) {
      right: calc(-30% + 110px);
      width: 50px;
    }
  }
}

.PagePainters_painter__uFSCZ {
  &:hover {
    #PaintersSlider {
      .slick-prev {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 0.8) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
      .slick-next {
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8) 50%,
          rgba(0, 0, 0, 1) 100%
        );
      }
    }
  }
}
