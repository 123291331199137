.partners {
  background-color: black;
  padding-top: 50px;
  overflow-x: hidden;
  // padding-bottom: 100px;

  min-height: 700px;

  nav {
    padding-top: 15px;
    margin-bottom: 20px;
  }

  &__mobile {
    padding-bottom: 120px;
  }
}

.title {
  color: white;
  position: relative;

  font-weight: 700;
  font-size: 35px;
  line-height: 44px;

  &__mobile {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -7px;

      width: 100vw;
      height: 1px;
      background-color: #fff;
    }
  }
}
