.tableColor {
  cursor: pointer;
  background: linear-gradient(180deg, #2aa89a 0%, rgba(42, 168, 154, 0) 100%);
}

.tablePickColor {
  background: linear-gradient(
    180deg,
    #4f4c93 0%,
    rgba(214, 51, 138, 0) 138.57%
  );
}

.tableBlockColor {
  cursor: unset;
  background: linear-gradient(
    180deg,
    #d6338a 0%,
    rgba(214, 51, 138, 0) 138.57%
  );
}

.last {
  border-right: none !important;
}
