.arendaGrid {
  padding: 40px 100px 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  background-color: #000;
  color: #fff;

  @media (max-width: 1180px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 768px) {
    padding: 20px 50px 20px;
    gap: 20px;
  }

  figure {
    margin: 0;
    position: relative;

    @media (max-width: 1300px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      left: -30px;
    }

    @media (max-width: 600px) {
      width: 470px;
    }
    @media (max-width: 570px) {
      width: 400px;
    }
    @media (max-width: 520px) {
      width: 300px;
      left: 0px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      z-index: 2;
      position: relative;

      @media (max-width: 768px) {
        height: 220px;
      }
    }
    div {
      position: absolute;
      left: 20px;
      right: -20px;
      top: 20px;
      border: 1px solid #fff;
      border-radius: 15px;
      width: 100%;
      height: 100%;

      @media (max-width: 768px) {
        border-radius: 15px;
        height: 220px;
        right: -100px;
      }
    }
  }

  div {
    h4 {
      font-weight: 700;
      text-transform: uppercase;
      font-size: 30px;
      letter-spacing: 10px;

      @media (max-width: 570px) {
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 6px;
      }
    }
    div {
      display: flex;
      align-items: flex-end;
      gap: 70px;
      margin-top: 60px;

      @media (max-width: 700px) {
        margin-top: 20px;
        gap: 20px;
        flex-direction: column;
      }
      p {
        width: 70%;
        font-size: 26px;
        font-weight: 300;

        @media (max-width: 1300px) {
          width: 100%;
          font-size: 20px;
        }
      }

      button {
        font-family: 'Geometria';
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 50%;
        border: none;
        border-radius: 5px;
        font-weight: 300;
        padding: 15px 40px;
        display: flex;
        color: #fff;
        gap: 10px;
        background: #4f4c93;
        font-size: 20px;

        img {
          margin-left: 10px;
          width: 20px;
        }

        @media (max-width: 1300px) {
          width: 60%;
          font-size: 16px;
          padding: 15px 10px;
        }
        @media (max-width: 700px) {
          padding: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
        }
      }
      button.center {
        background-color: #2aa89a;
        align-self: center;
      }
    }
  }
}

.wrapper {
  position: relative;
}

.graph {
  position: absolute;
  top: 270px;
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  align-items: normal !important;
  z-index: 3;

  @media (max-width: 1665px) {
    left: -100px;
  }
  @media (max-width: 1465px) {
    left: -250px;
  }
  @media (max-width: 1180px) {
    left: 0px;
  }

  .close {
    display: flex;
    background-color: #4f4c93;
    margin-top: 0;
    padding: 10px;
    justify-content: flex-end;
    span {
      cursor: pointer;
      font-weight: 300;
      text-align: center;
      width: 55px;
      height: 45px;
      border: 1px solid #ffffff;
      font-size: 40px;
    }
  }
}

.dateTitle {
  margin-top: 0 !important;
  flex-direction: row !important;
  width: 800px;
  color: #4f4c93;
  background-color: #ffffff;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  @media (max-width: 920px) {
    width: 700px;
  }
  @media (max-width: 820px) {
    width: 500px;
  }
  @media (max-width: 450px) {
    width: 300px;
  }

  p.date {
    margin-left: 10px;
    font-size: 24px;
    font-weight: 700;
  }

  img {
    width: 40px;
    height: 40px;
    &:first-of-type {
      transform: rotate(180deg);
    }
  }
}

.tableTime {
  border-collapse: collapse;

  tr {
    height: 70px;
  }
  tr.date {
    height: 20px;
  }
  td,
  th {
    border: 1px solid #fff;
    width: 50px;
    text-align: center;
  }
  .budni {
    background-color: #4f4c93;
  }
  .vihi {
    background-color: #2aa89a;
  }

  tr {
    td,
    th {
      &:first-of-type {
        border-left: none;
      }
      &:last-of-type {
        border-right: none;
      }
    }
  }
}
