.nonSlideMiniature {
  display: grid;
  width: 100%;
  gap: 10px;

  @media (max-width: 768px) {
    height: 80px;
  }

  .miniature {
    aspect-ratio: 1/0.07 !important;
  }
}

.firstString {
  display: flex;
  align-items: flex-end;
  padding-top: 100px;
  margin-bottom: 20px;
  .backLink {
    width: auto;
  }
  .profileTitle {
    margin: 0 !important;
    flex: 1;
  }
  span {
    margin-bottom: 0 !important;
    font-size: 18px !important;
    color: #000 !important;
  }
  a {
    padding-top: 0;
  }
}

.main_info {
  margin-bottom: 55px;
  margin-top: 130px;

  .profileTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 34px;
        font-weight: 600;
        color: #000;
        margin-bottom: 30px;
      }

      .author {
        font-size: 22px;
        font-weight: 300;
        color: #50535a;
        text-decoration: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      font-size: 27px;
      font-weight: 500;
      color: #50535a;

      text-decoration: none;

      &:hover {
        svg {
          transform: translateX(-7px);
        }
      }

      svg {
        margin-right: 10px;
        transition: transform 0.3s ease;
      }
    }
  }

  .grid_container {
    display: grid;
    grid-template-columns: 2fr 3fr;

    .profileImg {
      .slide {
        width: 100%;
        height: 100%;
        // box-sizing: border-box;

        overflow: hidden;

        &:focus {
          outline: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          // border: 8px solid #d4d8da;
          box-sizing: border-box;
          margin-bottom: 53px;

          cursor: pointer;

          &.miniature {
            max-width: 1000px;
            margin: 0 auto;
            width: 90% !important;
            aspect-ratio: 1/0.7;

            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .miniSlider {
      display: flex;
      margin: 0 auto;
      min-height: 80px;

      .miniature {
        width: 82px !important;
      }
    }
    .profileInfo {
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
      margin-left: 50px;

      & > dl {
        display: flex;
        flex-direction: column;

        div {
          display: grid;
          grid-template-columns: 1fr 2fr;
          gap: 5px;
          margin-bottom: 10px;
        }

        .info_title {
          font-size: 18px;
          font-weight: 400;
          color: #000;
          // margin-bottom: 5px;
        }

        .info_param {
          font-size: 18px;
          font-weight: 400;
          color: #000;
          // margin-bottom: 20px;

          a {
            font-size: 18px;
            font-weight: 500;
            color: #000;
            margin-bottom: 20px;
          }
        }
      }

      .buy_block {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 30px;

        .price {
          font-size: 36px;
          font-weight: 500;
          color: #50535a;
        }

        button {
          position: relative;

          min-width: 200px;
          height: 61px;

          font-family: "Geometria", sans-serif;
          font-size: 24px;
          font-weight: 500;

          padding: 15px 38px;
          border: none;

          color: #ffffff;
          background-color: #d6358c;

          cursor: pointer;

          overflow: hidden;

          // transition: left 0.3s ease;

          &::before {
            content: "";
            display: block;
            position: absolute;
            opacity: 1;

            left: -30px;
            top: 31%;

            height: 20px;
            width: 5px;
            background-color: #f0e400;

            transform: skew(-25deg);
            transition: all 0.3s ease;
          }

          &.saled {
            background-color: #d4d8da;
            color: #50535a;
            cursor: auto;
          }

          &:hover {
            &::before {
              left: 17px;
            }
          }

          &:disabled {
            outline: 1px solid #d6358c;
            background-color: white;
            color: #d6358c;

            &::before {
              opacity: 0;
            }
          }
        }
      }
    }
  }
}

.description {
  margin-bottom: 80px;

  p {
    font-size: 24px;
    font-weight: 500;

    margin-bottom: 23px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .description_text {
    p {
      font-size: 20px;
      font-weight: 300;
      color: #50535a;
    }
  }
}

.mobile_main_info {
  margin-bottom: 55px;

  .profileTitle {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;

    & > div {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 18px;
        font-weight: 500;
        color: #50535a;
        margin-bottom: 8px;
      }

      .author {
        font-size: 14px;
        font-weight: 300;
        color: #50535a;
        text-decoration: none;
      }
    }
  }

  .grid_container {
    .profileImg {
      grid-area: 1 / 1 / 2 / 2;
      display: block;

      .slide {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        justify-content: center;

        img {
          width: 100%;
          // border: 8px solid #d4d8da;
          box-sizing: border-box;

          &.miniature {
            max-width: 1000px;
            width: 100%;

            height: 100%;

            object-fit: cover;
            object-position: center;
          }
        }
      }
    }

    .profileInfo {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > dl {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        margin-bottom: 30px;

        & > div {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 8px;

          dd {
            text-align: right;
          }
        }

        .info_title {
          font-size: 14px;
          font-weight: 300;
          color: #000;
        }

        .info_param {
          font-size: 14px;
          font-weight: 300;
          color: #50535a;
        }
      }

      .buy_block {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .price {
          font-size: 22px;
          font-weight: 500;
          color: #50535a;
        }

        button {
          font-family: "Geometria", sans-serif;
          font-size: 14px;
          font-weight: 500;

          padding: 7px 17px;
          border: none;

          color: #ffffff;
          background-color: #d6358c;

          cursor: pointer;

          transition: all 0.3s ease;

          @media (max-width: 900px) {
            min-width: 105px;
          }

          &:disabled {
            outline: 1px solid #d6358c;
            background-color: white;
            color: #d6358c;

            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.backLink {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  // padding-top: 110px;

  margin: 0 auto;

  font-weight: 500;
  font-size: 27px;
  line-height: 44px;

  & a {
    color: #50535a;
    text-decoration: none;

    svg {
      position: relative;
      // top: 1px;
      transition: all 0.3s ease;
    }

    &:hover {
      svg {
        transform: translateX(-10px);
      }
    }
  }

  @media (max-width: 900px) {
    // margin-top: 10px;

    margin-top: 0;
    padding-top: 100px;

    svg {
      rect {
        transition: fill 0.3s ease;
      }

      &:hover {
        rect {
          fill: #ab1a68;
        }
      }
      &:active {
        rect {
          fill: #ab1a68;
        }
      }
    }
  }
}
