.sectionPainter {
  margin-top: 150px;

  &__mobile {
    margin-top: 100px;
  }
}

.info {
  display: grid;
  grid-template-columns: 315px 1fr;
  grid-auto-rows: minmax(30px, min-content) minmax(40px, min-content)
    minmax(30px, min-content) auto 62px;
  column-gap: 30px;

  height: 480px;
  max-height: 480px;

  &__mobile {
    height: unset;
    max-height: unset;

    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }
}

.image {
  grid-area: 1/1/5/2;

  &__mobile {
    grid-area: 3/1/4/2;
    // height: 320px;
    margin-bottom: 10px;

    img {
      object-position: left top;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    // margin-bottom: 10px;
  }
}

.name {
  grid-area: 1/2/2/3;

  margin-top: 0;
  height: max-content;

  font-weight: 500;
  font-size: 25px;
  line-height: 31px;
  color: #50535a;
  // margin-bottom: 0px;

  &__mobile {
    grid-area: 1/1/2/2;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
  }
}

.short {
  grid-area: 2/2/3/3;

  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #50535a;
  display: block;

  &__mobile {
    grid-area: 2/1/3/2;

    margin-bottom: 15px;
    font-size: 16px;
    line-height: 20px;
  }
}

.title_h2 {
  grid-area: 3/2/4/3;

  display: block;

  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #50535a;

  margin-bottom: 7px;

  &__mobile {
    grid-area: 5/1/6/2;
    font-size: 20px;
    line-height: 25px;
    // height: 30px;
  }
}

.biography {
  grid-area: 4/2/5/3;

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;

    color: #000000;

    margin-bottom: 15px;

    &:last-child {
      padding-bottom: 20px;
    }
  }

  &__mobile {
    grid-area: 6/1/7/2;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.social {
  grid-area: 5/1/6/2;
  align-self: flex-end;

  a {
    background-color: #50535a;
    width: 37px;
    height: 37px;
    border-radius: 50%;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    margin-right: 20px;

    transition: background-color 0.3s ease;

    &:hover {
      background-color: #d6338a;
    }

    svg {
      height: 60%;
      width: 60%;
    }
  }

  &__mobile {
    grid-area: 4/1/5/2;
    margin-bottom: 20px;

    a {
      background-color: transparent !important;
      margin-right: 0;

      svg {
        path {
          fill: #50535a;
        }
      }

      &:first-of-type {
        justify-content: flex-start;
      }

      &:last-of-type {
        width: 45px;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.pictures {
  margin-top: 130px;
  margin-bottom: 80px;

  .title_h2 {
    font-weight: 700;
    font-size: 25px;
    line-height: 31px;

    color: #50535a;

    margin-bottom: 64px;
  }

  &__mobile {
    margin-top: 20px;
    margin-bottom: 0;

    .title_h2 {
      margin-bottom: 30px;

      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: min-content;
  column-gap: 50px;
  row-gap: 45px;

  a {
    display: block;
    text-decoration: none;
    position: relative;
    overflow: hidden;

    border: 4px solid #50535a;

    &:hover {
      .pic_wrapper {
        transform: translateY(0);
      }

      img {
        // filter: blur(2px);
        outline-width: 0;
        outline-offset: 3px;
      }
    }
  }

  &__mobile {
    display: flex;
    flex-direction: column;
    grid-gap: 0;

    a {
      border: unset;

      &:hover {
        img {
          // filter: blur(0);
        }
      }
    }
  }
}

.picture {
  position: relative;

  img:first-of-type {
    width: 100%;
    height: 145px;
    object-fit: contain;
    display: block;

    transition: all 0.3s ease;
  }

  img:last-of-type {
    // object-fit: cover;

    filter: blur(4px);
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: -1;

    transform: translate(-50%, -50%);

    opacity: 0.9;
  }

  &__mobile {
    margin-bottom: 30px;

    img {
      height: auto;

      &:first-of-type {
        width: 100%;
        height: auto;
      }

      &:last-of-type {
        display: none;
      }
    }
  }
}

.pic_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0%;
  padding: 5px 10px;

  background-color: rgba(black, 0.7);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  transform: translateY(150px);
  transition: transform 0.3s ease;

  &__mobile {
    position: static;
    transform: unset;
    background-color: transparent;
    padding: 10px 0 0;
  }
}

.title {
  color: white;

  &__mobile {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #50535a;
  }
}

.price {
  color: white;
  align-self: flex-end;

  &__mobile {
    color: #50535a;
    font-weight: 300;
    font-size: 17px;
    line-height: 21px;
    padding-top: 10px;
  }
}

.backArrow {
  grid-row: 1/2;
  grid-column: 3/4;

  display: flex;
  justify-content: flex-end;

  color: #50535a;

  font-family: 'Geometria';
  font-style: normal;
  font-weight: 500;
  font-size: 27.1127px;
  line-height: 44px;

  & a {
    color: #50535a;
    text-decoration: none;

    svg {
      transition: transform 0.3s ease;
    }

    &:hover {
      svg {
        transform: translateX(-10px);
      }
    }
  }

  @media (max-width: 900px) {
    grid-row: 7/8;
    grid-column: 2/3;

    line-height: 0;
    margin-top: 30px;
  }
}
