.promo {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  height: 100vh;
  width: 100%;

  padding: 150px 30px 50px;
  box-sizing: border-box;

  &__mobile {
    padding: 80px 0 50px;
    height: 100dvh;
    background-color: #1a1b20;
    background-position: bottom -270px center;
    // background-size: 300% 500%;
  }
}

.container {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;

  @media (max-width: 1160px) {
    display: block;
  }
}

.promo_title {
  font-weight: 600;
  font-size: 45px;
  line-height: 1.2;
  color: white;
  margin-top: 0;

  &__mobile {
    font-size: 33px;
    margin-bottom: 10px;
    // line-height: 1.1;
    padding-left: 10px;
  }
}

.regisrtation {
  display: block;
  background-color: #ba3131;
  border: none;
  border-radius: 30px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);

  font-weight: 500;
  font-size: 28px;
  text-transform: uppercase;

  color: white;
  text-decoration: none;

  width: min-content;
  padding: 10px 20px 10px;

  margin: 30px 0;

  cursor: pointer;
  align-self: center;

  transition: transform 0.3s ease;

  &:hover {
    transform: translate(-3px, -3px);
    box-shadow: 7px 7px 4px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translate(3px, 3px);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  }

  &__mobile {
    font-size: 20px;
    margin: 15px 0;
  }
}

.place {
  font-size: 20px;
  font-weight: 500;
  color: white;

  &__mobile {
    // font-size: 20px;
    // line-height: 25px;
    padding-left: 10px;
    font-size: 16px;
  }
}

// .info {
//   width: auto;
//   box-sizing: border-box;

//   &__mobile {

//   }
// }

// ==========================

.city {
  font-size: 20px;
  font-weight: 500;
  color: white;

  &__mobile {
    font-size: 15px;
    font-weight: 400;
    // line-height: 25px;
    padding-left: 10px;
  }
}

.dates {
  background-color: #fff;
  color: black;
  font-size: 35px;
  font-weight: 700;
  padding: 5px 10px;
  width: min-content;
  white-space: nowrap;

  margin: 5px 0 30px;

  &__mobile {
    font-size: 24px;
    margin-bottom: 15px;
  }
}

.text {
  color: white;
  padding-left: 20px;

  & > ul {
    & > li {
      font-size: 20px;
    }
  }

  &__mobile {
    // padding: 10px 15px 10px 30px;
    padding-left: 0 0 0 30px;
    color: white;

    & > ul {
      & > li {
        font-size: 16px;
        line-height: 1.4;
        font-weight: 400;
      }
    }
  }
}

// .slider {
//   justify-self: flex-end;

//   img {
//   }

//   &__mobile {
//     position: absolute;
//     width: 100%;
//     bottom: 32%;
//     transform: translateY(50%);
//   }
// }
