.dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      border-radius: 100%;
      width: 5px;
      height: 5px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: gray;

      &::before {
        width: 14px;
        height: 14px;
        opacity: 1;
        color: #2aa89a;
        position: absolute;
        top: -2px;
        background-color: #2aa89a;
      }
    }
  }
  li.slick-active {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      border-radius: 100%;
      width: 5px;
      height: 5px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: white;

      &::before {
        width: 14px;
        height: 14px;
        opacity: 1;
        color: #2aa89a;
        position: absolute;
        top: -2px;
        background-color: #2aa89a;
      }
    }
  }
}

.slick-prev {
  left: -35px;
}
.slick-next {
  right: -35px;
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  line-height: 1;
  opacity: 1;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 1150px) {
  .slick-prev {
    left: 15px;
  }
  .slick-next {
    right: 15px;
  }
}
