.address {
  padding: 80px 150px 0px;
  background-color: #000;
  display: flex;
  gap: 50px;
  color: #fff;

  @media (max-width: 1300px) {
    padding: 40px 70px 60px;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    padding: 20px 50px 20px;
    gap: 0px;
  }

  p {
    padding-left: 20px;
    font-weight: 300;
    line-height: 1.8;

    @media (max-width: 768px) {
      padding-left: 0;
      gap: 0px;
    }
  }

  figure {
    width: 2000px;
    position: relative;

    @media (max-width: 1300px) {
      width: 100%;
    }

    @media (max-width: 768px) {
      left: -40px;
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 15px;
      position: relative;

      @media (max-width: 768px) {
        height: 220px;
      }
    }
    div {
      position: absolute;
      left: 20px;
      right: -20px;
      top: 20px;
      border: 1px solid #fff;
      border-radius: 15px;
      width: 100%;
      height: 100%;

      @media (max-width: 768px) {
        border-radius: 15px;
        height: 220px;
        right: -100px;
      }
    }
  }
}

.addressHeader {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: 30px;
  width: max-content;
  font-weight: 500;
  letter-spacing: 10px;
  font-size: 26px;
  background-color: #fff;
  color: #000;
  border-radius: 15px;

  @media (max-width: 768px) {
    width: unset;
    padding: 10px 10px;
    letter-spacing: 5px;
    font-size: 16px;
    margin-bottom: 20px;
  }

  img {
    width: 60px;

    @media (max-width: 768px) {
      width: 30px;
    }
  }
}

.addressImages {
  padding: 50px 150px 70px;
  background-color: #000;
  display: grid;
  grid-template-columns: 8fr 1fr 1fr;
  gap: 50px;
  transition: all 0.3s ease;

  @media (max-width: 1200px) {
    padding: 40px 80px;
    gap: 20px;
  }
  @media (max-width: 1000px) {
    padding: 20px 40px;
    gap: 10px;
  }
  div {
    position: relative;
    cursor: pointer;

    p {
      position: absolute;
      top: 400px;
      left: -78px;
      transform: rotate(-90deg);
      color: #fff;
      width: 195px;
      padding: 20px;
      border-radius: 15px;
      background: #d6338a;

      span {
        display: block;
        margin-left: 20px;

        @media (max-width: 1400px) {
          margin-left: 13px;
        }
        @media (max-width: 1200px) {
          margin-left: 10px;
        }
        @media (max-width: 1200px) {
          margin-left: 8px;
        }
      }

      @media (min-width: 2400px) {
        top: 349px;
        width: 180px;
        font-size: 26px;
        left: -58px;
      }
      @media (max-width: 1400px) {
        padding: 10px;
        top: 200px;
        width: 110px;
        font-size: 16px;
        left: -35px;
      }

      @media (max-width: 1200px) {
        border-radius: 10px;
        padding: 5px;
        width: 100px;
        font-size: 12px;
      }

      @media (max-width: 768px) {
        padding: 2px 5px;
        width: 80px;
        font-size: 10px;
        top: 270px;
        left: -30px;
      }
    }

    &:nth-of-type(2) {
      P {
        background: #f0e400;
        color: #000;
      }
    }
    &:nth-of-type(3) {
      p {
        background: #2aa89a;
      }
    }
  }

  img {
    border-radius: 20px;
    width: 100%;
    height: 700px;
    object-fit: cover;

    @media (max-width: 1400px) {
      height: 400px;
    }
  }
}
