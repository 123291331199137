.audioPlayer {
  position: relative;
  padding: 0.75rem;
  background-color: transparent;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapperInner {
  margin-bottom: 0.25rem;
  text-align: center;

  .songName {
    color: inherit;
    font-weight: 700;
  }

  .author {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

.iconsWrapper {
  display: grid;
  margin-top: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.icons {
  display: flex;
  gap: 1rem;
  justify-self: center;
  align-items: center;
}

.mute {
  display: flex;
  gap: 0.75rem;
  align-items: center;

  @media (min-width: 768px) {
    justify-self: end;
  }
}

.spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
