.react-calendar {
  width: 350px;
  max-width: 100%;
  background: transparent;
  line-height: 1.125em;
  height: 320px;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: white;
  font-size: 24px;
}

.react-calendar__navigation button:disabled {
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
abbr {
  text-decoration: none !important;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font: inherit;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: linear-gradient(180deg, #2aa89a 0%, rgba(42, 168, 154, 0) 100%);
  text-align: center;
  line-height: 16px;
  font: inherit;
  font-size: 0.833em;
  color: white;
}

.react-calendar__tile:disabled {
  background: linear-gradient(
    180deg,
    #d6338a 0%,
    rgba(214, 51, 138, 0) 138.57%
  );
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
}

.react-calendar__tile--now {
  background: linear-gradient(
    180deg,
    #4f4c93 0%,
    rgba(214, 51, 138, 0) 138.57%
  );
}

.react-calendar__tile--now:disabled {
  background: linear-gradient(
    180deg,
    #4f4c93 0%,
    rgba(214, 51, 138, 0) 138.57%
  );
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: transparent;
}

.react-calendar__tile--hasActive {
  background: linear-gradient(
    180deg,
    #d6338a 0%,
    rgba(214, 51, 138, 0) 138.57%
  );
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #4f4c93;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #4f4c93;
}

.react-calendar--selectRange .react-calendar__tile--hover {
}
