.place {
  width: auto;
  // height: 115px;

  // overflow: hidden;
  position: relative;
  z-index: 10;
  box-sizing: border-box;
}

.image {
  width: 100%;
  // height: 100%;
  object-fit: cover;
  border-radius: 10px;
  aspect-ratio: 1 / 1;
}

.title {
  color: white;
  // outline: 3px solid red;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
}

.location {
  color: white;
  font-weight: 300;
  font-size: 13px;
  line-height: 17px;
}
