.aboutCluster {
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 70px;
  padding: 50px 150px 70px;

  @media (max-width: 1150px) {
    padding: 50px 50px 70px;
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 768px) {
    padding: 20px 50px 20px;
    gap: 20px;
  }
}

.headerCluster {
  h3 {
    padding: 10px 20px;
    width: max-content;
    font-weight: 500;
    font-size: 46px;
    border: 2px solid #000;
    border-radius: 15px;
  }
  p {
    margin-top: 40px;
    font-weight: 500;
    font-size: 30px;

    @media (max-width: 768px) {
      margin-top: 20px;
      font-size: 20px;
    }
  }
}

.paragraphCluster {
  font-size: 24px;

  @media (max-width: 1400px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.panorama {
  padding: 30px 0px 60px;
  background-color: #000;
  color: #fff;

  img {
    width: 100%;

    @media (max-width: 768px) {
      height: 400px;
      object-fit: cover;
    }
  }

  p {
    font-weight: 300;
    font-size: 36px;
    margin-left: 150px;

    @media (max-width: 1180px) {
      font-size: 26px;
      margin-left: 80px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
      margin-left: 50px;
    }
  }
  @media (max-width: 768px) {
    padding: 20px 0px 20px;
  }
}

.advantages {
  background-color: #fff;
  padding: 30px 100px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    padding: 20px 0px 20px;
  }

  h4 {
    padding: 20px 60px;
    width: max-content;
    font-weight: 500;
    font-size: 30px;
    border: 2px solid #000;
    border-radius: 15px;
    box-shadow: 4px 13px 19px rgba(0, 0, 0, 0.38);

    @media (max-width: 768px) {
      padding: 20px 20px;
      font-weight: 500;
      font-size: 13px;
      border: 2px solid #000;
      border-radius: 15px;
    }
  }

  div {
    margin-top: 50px;
    display: flex;
    gap: 40px;
    color: #fff;

    @media (max-width: 1180px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
    }

    .advantage {
      padding: 20px;
      border-radius: 18px;
      background-color: #d6338a;
      width: 200px;
      height: 200px;
      box-shadow: 12px 12px 13px 0px rgba(0, 0, 0, 0.15);

      &:nth-of-type(2) {
        background-color: #f0e400;
      }
      &:nth-of-type(3) {
        background-color: #2aa89a;
      }
      &:nth-of-type(4) {
        background-color: #4f4c93;
      }
    }
  }
}
