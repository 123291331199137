.content {
  display: grid;
  grid-template-columns: 500px 500px;
  justify-content: center;
  gap: 50px;
  // display: flex;
  // align-items: center;
  flex: 1 0 auto;
}

.leftColumn {
  position: relative;
  height: 460px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.49);

  background: linear-gradient(
    177deg,
    rgba(255, 255, 255, 0.24) 3.61%,
    rgba(255, 255, 255, 0) 104.83%
  );

  backdrop-filter: blur(20px);
  padding: 40px 20px;
}

.title {
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #f0e400;
  font-size: 30px;
  font-weight: 700;
}

.desc {
  flex: 1 1 auto;
  text-transform: lowercase;
  font-size: 20px;
}

.list {
  text-transform: uppercase;
  list-style-type: none;
  font-size: 30px;
  li {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  svg {
    width: 30px;
  }
}

.rightColumn {
  position: relative;
  display: flex;
  height: 460px;

  flex-direction: column;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.49);

  background: linear-gradient(
    177deg,
    rgba(255, 255, 255, 0.24) 3.61%,
    rgba(255, 255, 255, 0) 104.83%
  );

  backdrop-filter: blur(20px);
  padding: 40px 20px;
}

.bavkSvg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;
  height: 60%;
}

@media (max-width: 1350px) {
  .list,
  .title {
    font-size: 20px;
  }
  .content {
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 980px) {
  .list,
  .title {
    font-size: 16px;
  }
  .desc {
    font-size: 16px;
    width: 100%;
  }
}

@media (max-width: 980px) {
  .content {
    grid-template-columns: 1fr;
    margin-right: 20px;
  }
  .leftColumn,
  .rightColumn {
    height: 320px;
  }
  .rightColumn {
    margin-bottom: 20px;
  }
}
