.productImages {
  display: flex;
  height: 100%;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  @media (max-width: 900px) {

  }
}

.productImage {
  width: 345px;
  height: 345px;

  margin-right: 29px;
  cursor: pointer;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 1200px) {
    width: 100%;
    height: auto;

    margin-right: 0;
    margin-bottom: 12px;
  }
}

.productAdditionalImage {
  width: 104px;
  height: 104px;
  opacity: 0.4;

  margin-bottom: 8px;
  cursor: pointer;

  transition: opacity 0.3s ease;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media (max-width: 1200px) {
      width: 100%;
    }
  }
  &.active {
    opacity: 1;
  }
  &:focus-visible {
    outline: none;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 1200px) {
    // width: 82px !important;
    // height: auto;
    height: min-content;
    // padding: 0 10px;
  }
}
.slider {
  position: relative;
  overflow: hidden;
  height: 345px;

  @media (max-width: 1200px) {
    width: 100%;
    height: min-content;
  }
}
.afterArrow,
.beforeArrow {
  display: flex;
  padding-bottom: 6px;
  justify-content: center;
  align-items: flex-end;
  position: absolute;
  width: 104px;
  height: 51px;
  background: linear-gradient(0deg, #000000 -19.63%, rgba(0, 0, 0, 0) 97.78%);
  z-index: 100;

  cursor: pointer;

  @media (max-width: 1200px) {
    width: 83px;
    padding-bottom: 0;
  }
}

.afterArrow {
  bottom: 0;

  @media (max-width: 1200px) {
    right: 0;
    top: 50%;
    bottom: unset;
    background: linear-gradient(
      270deg,
      #000000 -19.63%,
      rgba(0, 0, 0, 0) 97.78%
    );

    transform: translateY(-55%);
    height: 100%;
    width: 50px;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(270deg) !important;
    }
  }

  svg {
    transform: translateY(-10px);
    transition: transform 0.3s ease;
  }

  &:hover {
    svg {
      transform: translateY(0px);
    }
  }
}

.beforeArrow {
  transform: rotateZ(180deg);
  top: 0;

  @media (max-width: 1200px) {
    left: 0;
    top: 50%;
    bottom: unset;
    background: linear-gradient(
      90deg,
      #000000 -19.63%,
      rgba(0, 0, 0, 0) 97.78%
    );

    transform: translateY(-55%);
    height: 100%;
    width: 50px;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%) rotate(90deg) !important;
    }
  }

  svg {
    transform: translateY(-10px);
    transition: transform 0.3s ease;
  }

  &:hover {
    svg {
      transform: translateY(0px);
    }
  }
}
