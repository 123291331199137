.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8000;
  width: 100vw;
  height: 100vh;

  background-color: rgba(black, .7);

  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_content {
  position: relative;
  height: 80%;
  width: 80%;

  box-sizing: border-box;

  transform: scale(.5);
  transition: .5s;

  display: flex;
  justify-content: center;
  align-items: center;

  &__active {
    transform: scale(1);
  }

  & > div {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    width: unset;

    @media(max-width: 1000px) {
      height: unset;
      width: 100%;
    }
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  button {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: transparent;
    border: none;
    width: 55px;
    height: 55px;
    padding: 0;
    cursor: pointer;
    transform: translate(50%, -50%) rotate(0deg);
    transition: .3s;

    &:hover {
      transform: translate(50%, -50%) rotate(90deg);
    }

    &::before, &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 2px;
      width: 84px;
      background-color: #f0e400;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
