.main_page_wrapper {
  &.black_bg {
    background-color: #000;
    padding-bottom: 0px;

    h1,
    h2,
    p,
    span,
    a {
      color: #fff;
    }

    .image_wrapper {
      img {
        background-color: #000;
      }
    }
  }
}

.main_page_container {
  z-index: 2;
  padding-top: 150px;
  position: relative;
  padding-bottom: 50px;

  display: grid;
  grid-template-columns: 1.5fr 3fr 0.3fr;
  column-gap: 50px;

  h1,
  h2 {
    font-size: 30px;
    font-weight: 500;
    color: #50535a;

    text-transform: uppercase;
    letter-spacing: 10px;

    word-wrap: break-word;

    display: inline-block;
    width: 100%;
  }

  h1 {
    margin-top: 20px;
  }

  h2 {
    font-weight: 300;
    margin-bottom: 15px;
    font-size: 15px;
  }
}

.emptyText {
  margin-top: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 786px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.image_wrapper {
  display: block;
  width: 100%;
  aspect-ratio: 1/1.34;

  background-color: white;
  position: relative;

  img.mainImage {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top;
  }

  .loader {
    position: absolute;
    width: 100%;
  }
}

.next {
  margin-top: 20px;
  cursor: pointer;
  svg {
    width: 50px;
    height: 50px;
  }
}

.desc_wrapper {
  flex: 0 1 auto;

  display: flex;
  flex-direction: column;

  h2 {
    white-space: pre;
  }
}

.paletteWrapper {
  position: relative;
  z-index: 1;
  top: 13px;
  margin-bottom: 43px;

  .palette_bg {
    margin-bottom: -83px;

    img {
      display: block;
      width: 100%;
      height: 275px;
      object-fit: cover;
      mask-image: url('../../../../assets/img/smear.svg');
      mask-repeat: no-repeat;
      mask-size: 120% 275px;
      mask-position: left 50% top 0;
    }
  }
}

.slider_wrapper {
  margin-top: auto;

  position: relative;
  height: 178px;
  width: 660px;
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  & > div {
    position: static;
  }

  & ul {
    left: 0;
    bottom: 10px;
  }

  .slide {
    p {
      font-size: 24px;
      line-height: 1.2;
      color: #ffffff;
    }
  }
}

.mobile_paletteWrapper {
  position: relative;
  height: 1px;
  z-index: 1;

  .palette_bg {
    height: 260px;
    width: 100vw;
    background: url('../../../../assets/img/palette-bg.png') center center
      no-repeat;
    background-size: cover;
    position: absolute;
    bottom: -35px;
    left: 0;
    right: 0;
    z-index: -1;
  }
}

.mobile_main_page_container {
  display: flex;
  flex-direction: column;
  z-index: 2;
  margin-top: 100px;
  position: relative;
}

.mobile_slider_wrapper {
  z-index: 2;
  background-color: transparent;

  .slide {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      color: #ffffff;
    }
  }
}

.mobile_main_image_wrapper {
  position: relative;
  margin-bottom: calc(6vw + 35px);

  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  svg {
    position: absolute;
    top: 153px;
    margin: 0;
    cursor: pointer;

    &.prev {
      left: -20px;
    }

    &.next {
      right: -20px;
    }
  }

  img {
    height: 320px;
    box-shadow: none;
    align-self: center;
    margin-top: 30px;
    background-color: #000;
  }

  .info {
    h1 {
      font-size: 20px;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 5px;
      text-decoration: underline;
      font-weight: 500;
      margin-top: 8px;
      text-align: center;
      margin-bottom: 8px;
    }

    h2 {
      font-weight: 300;
      text-align: center;
    }

    div {
      display: flex;
      justify-content: center;
      margin-bottom: 17px;
    }

    span {
      color: #fff;
      font-size: 12px;
      letter-spacing: 2px;
      text-align: center;
    }
  }
}

.description {
  text-align: center;
  letter-spacing: 2px;
}

.sub_title {
  text-align: center;
  margin-top: 20px;

  font-size: 22px;
  font-weight: 500;
  letter-spacing: 4px;

  margin-bottom: 27px;
}

.noisy_canvas {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 700px;
  background-image: url('../../../../assets/img/white_noise.jpg');

  @media (max-width: 1000px) {
    height: 800px;
  }
}

.description_text {
  margin-bottom: 25px;
}

.backArrow {
  margin-top: 150px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    margin-top: 100px;
  }

  svg {
    transform: translateX(0);
    transition: transform 0.3s ease;
  }

  a {
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;
    text-decoration: none;
    color: #50535a;
  }

  &:hover {
    svg {
      transform: translateX(-10px);
    }
  }
}
