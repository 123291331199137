.ideas {
  font-size: 20px;
  gap: 50px;
  padding: 40px 150px 100px;
  display: flex;
  background-color: #fff;
  color: #000;

  @media (max-width: 1300px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    padding: 20px 50px 20px;
    gap: 20px;
    flex-direction: column;
  }

  .title {
    text-transform: uppercase;
    width: max-content;
    height: max-content;
    border: 2px solid #000;
    border-radius: 15px;
    padding: 20px 20px 5px;
    line-height: 0.8;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 10px;

    span {
      letter-spacing: 12px;
      font-size: 16px;
      font-weight: 300;
    }

    @media (max-width: 768px) {
      font-size: 26px;
    }
  }
}
