@font-face {
  font-family: 'Geometria';
  font-display: swap;
  src: url('../src/assets/fonts/Geometria-Light.woff2') format('woff2'),
    url('../src/assets/fonts/Geometria-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  font-display: swap;
  src: url('../src/assets/fonts/Geometria-Regular.woff2') format('woff2'),
    url('../src/assets/fonts/Geometria-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  font-display: swap;
  src: url('../src/assets/fonts/Geometria-Medium.woff2') format('woff2'),
    url('../src/assets/fonts/Geometria-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geometria';
  font-display: swap;
  src: url('../src/assets/fonts/Geometria-Bold.woff2') format('woff2'),
    url('../src/assets/fonts/Geometria-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Geometria', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
  margin: 0;
  padding: 0;
}

button {
  cursor: pointer;
  font-family: 'Geometria', sans-serif;
}
input {
  font-family: 'Geometria', sans-serif;
}
input::placeholder {
  font-family: 'Geometria', sans-serif;
}
a {
  text-decoration: none;
  color: white;
}

@media (max-width: 1000px) {
  #root {
    scroll-padding-top: 2.5rem;
  }
}
