.wrapper {
  background-color: #000;
}

.shop {
  display: flex;
  align-items: flex-start;

  padding-top: 0px;
  margin-bottom: 60px;
  margin-top: 42px;

  @media (max-width: 900px) {
    margin-bottom: 80px;

    flex-direction: column;
    align-items: center;
  }
}

.shopInfo {
  width: 228px;
  color: #fff;
  margin-right: 54px;
  @media (max-width: 900px) {
    margin-right: 0;
    width: 100%;
    margin-bottom: 25px;
  }
}

.shopImage {
  width: 228px;
  height: 228px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;

    @media (max-width: 900px) {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
}

.shopTitle {
  margin-bottom: 10px;

  color: #fff;
  text-decoration: none;
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100.3%;

  @media (max-width: 900px) {
    margin-bottom: 6px;

    font-size: 17px;
  }
}

.shopDescription {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.2;
}

.products {
  width: 100%;
  margin-bottom: 50px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  position: relative;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    width: 100%;
    justify-content: center;
    margin-bottom: 0;
  }
}

.productLink {
  color: #fff;
  text-decoration: none;
  display: block;

  & a {
    text-decoration: none;
  }

  @media (max-width: 900px) {
    display: block;
    width: 100%;
  }

  // &:nth-child(3n) {
  //   // background-color: red;
  //   .product {
  //     margin-right: 0;
  //   }
  // }
}

.product {
  width: 100%;
  // height: 268px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  // margin-right: 4px;
  background-size: contain;

  position: relative;

  cursor: pointer;

  & .productReadMore {
    position: absolute;
    z-index: 5;
    top: 0;

    display: flex;
    opacity: 0;
    height: 60px;

    padding-top: 10px;
    padding-left: 6px;

    // background: linear-gradient(
    //   180deg,
    //   rgba(0, 0, 0, 0.75) 0%,
    //   rgba(0, 0, 0, 0) 100%
    // );
    transition: opacity 0.5s ease;

    font-family: 'Geometria';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100.3%;

    &::after {
      content: url('../../../../assets/img/showroom/arrow.svg');
      display: block;
      margin-top: 1px;
      margin-left: 6px;
    }
  }

  &:hover {
    & .productReadMore {
      opacity: 1;

      @media (max-width: 900px) {
        opacity: 0;
      }
    }

    & .imageWrapper {
      &::before {
        opacity: .4;
      }
    }
  }

  @media (max-width: 900px) {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;

    margin-bottom: 11px;
  }
}

.productWrapper {
  position: relative;
  // width: 200px;
  height: 100%;

  @media (max-width: 900px) {
    height: unset;
    width: 100%;
    margin-bottom: 10px;

    &::before {
      content: '';
      padding-top: 100%;
      display: block;
    }
  }
}

.productInfo {
  position: absolute;
  bottom: 0;
  z-index: 5;

  padding: 8px;
  // height: 68px;
  min-height: 80px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: grid;
  box-sizing: border-box;
}

.productTitle {
  grid-row: 1/2;
  grid-column: 1/3;

  font-family: 'Geometria';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100.3%;
}

.productCost {
  margin-top: auto;
  grid-row: 2/3;
  grid-column: 1/2;
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 100.3%;
}

.productButton {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 200;

  margin-top: auto;
  grid-row: 2/3;
  grid-column: 2/3;
  // width: 66px;
  // height: 17px;
  padding: 2px 5px;
  margin-left: auto;
  background-color: #4f4b98;
  border: 0;
  color: #fff;

  font-family: 'Geometria';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100.3%;
  white-space: nowrap;
  cursor: pointer;

  transition: all 0.3s ease;

  &:hover {
    background-color: #403d79;
  }

  &:active {
    background-color: #403d79;
    color: #6b68a7;
  }
}

.imageWrapper {
  position: relative;
  // z-index: -1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: black;

    opacity: 0;
    transition: opacity .3s ease;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.backLink {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding-top: 110px;

  margin: 40px auto 15px;

  font-weight: 500;
  font-size: 27px;
  line-height: 44px;

  @media(max-width: 1000px) {
    padding-top: 100px;
    margin-bottom: 0;
  }

  & a {
    color: #fff;
    text-decoration: none;

    svg {
      position: relative;
      // top: 1px;
      transition: all 0.3s ease;
    }

    &:hover {
      svg {
        transform: translateX(-10px);
      }
    }
  }

  @media (max-width: 900px) {
    // margin-top: 10px;

    margin-top: 0;

    svg {
      rect {
        transition: fill 0.3s ease;
        fill:#ab1a68;
      }
      path {
        stroke: white;
      }

      &:hover {
        rect {
          fill: #ab1a68;
        }
      }
      &:active {
        rect {
          fill: #ab1a68;
        }
      }
    }
  }
}
