.section {
  background-color: black;
  padding-top: 200px;
  padding-bottom: 50px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;

  @media (max-width: 768px) {
    padding-top: 140px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 50px;
  row-gap: 50px;

  position: relative;

  @media (max-width: 1150px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (max-width: 768px) {
    column-gap: 30px;
    row-gap: 70px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.logo {
  position: relative;
  grid-area: 1/1/3/2;

  @media (max-width: 1150px) {
    grid-area: 2/1/3/2;
  }

  & > img {
    position: absolute;
    bottom: -100px;
    right: 0;

    @media (max-width: 768px) {
      width: 200px;
      bottom: unset;
      top: -20px;
    }
  }
}

.title {
  grid-area: 1/2/2/4;
  font-family: Geometria;
  color: white;
  font-weight: 600;
  font-size: 35px;
  line-height: 1;

  margin-top: 0;

  @media (max-width: 1150px) {
    grid-area: 1/1/2/3;
  }

  & > span {
    font-weight: 300;
  }
}

.text1 {
  grid-area: 2/2/3/3;
  color: white;
  font-size: 12px;

  @media (max-width: 1150px) {
    grid-area: 2/2/3/3;
  }
}

.text2 {
  grid-area: 2/3/3/4;
  color: white;
  font-size: 12px;

  @media (max-width: 1150px) {
    grid-area: 3/1/4/3;
    justify-self: flex-end;
  }
}

.order {
  grid-area: 2/4/3/5;
  align-self: flex-end;
  background-color: #4f4898;
  padding: 15px 25px 15px 100px;
  position: relative;
  border-radius: 50px;

  font-family: Geometria;
  font-size: 20px;
  line-height: 1.5;
  color: white;

  @media (max-width: 1150px) {
    grid-area: 4/1/5/3;
  }

  @media (max-width: 768px) {
    width: min-content;
    justify-self: center;
  }

  & > img {
    position: absolute;
    left: 15px;
    bottom: -5px;
  }
}

.arrow {
  position: absolute;
  top: 150px;
  right: 0px;

  @media (max-width: 1150px) {
    right: 30%;
    rotate: 110deg;
  }
  @media (max-width: 768px) {
    top: unset;
    bottom: 27%;
  }
}
