.clusterRoadmap {
  background-color: #000;
  color: #fff;
  padding-left: 150px;
  padding-bottom: 100px;
  padding-right: 150px;
  padding-top: 30px;

  @media (max-width: 768px) {
    padding-left: 65px;
    padding-bottom: 50px;
    padding-right: 20px;
  }
}

.header {
  display: flex;
  flex-wrap: wrap;
  column-gap: 100px;
  justify-content: space-between;
  row-gap: 20px;
  margin-bottom: 50px;
}

.box {
  display: flex;
  gap: 40px;
  @media (max-width: 960px) {
    flex-direction: column;
    gap: 10px;
  }
}

.about {
  text-transform: uppercase;
  padding: 20px;
  border: 2px solid #fff;
  border-radius: 20px;
  font-size: 40px;
  height: max-content;
  letter-spacing: 0.5rem;

  @media (max-width: 578px) {
    font-size: 26px;
  }
}

.title {
  font-weight: 700;
  font-size: 40px;
  text-transform: uppercase;

  @media (max-width: 578px) {
    font-size: 22px;
  }

  &Decoration {
    letter-spacing: 1rem;
    color: #d6338a;
    font-size: 80px;

    @media (max-width: 578px) {
      letter-spacing: 0.8rem;
      font-size: 40px;
    }
  }
}

.description {
  width: 250px;
}

.history {
  cursor: grab;
  padding-bottom: 350px;
  display: flex;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 0px;
    height: 5px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
}

.historyItem {
  width: 450px;
  position: relative;
  display: flex;

  &:nth-of-type(3n + 1) {
    color: #4f4c93;

    .historyTitle {
      background-color: #4f4c93;
    }

    .historyBox {
      &::before {
        border: 6px solid #4f4c93;
      }
    }
  }
  &:nth-of-type(3n + 2) {
    color: #2aa89a;
    .historyTitle {
      background-color: #2aa89a;
    }
    .historyBox {
      &::before {
        border: 6px solid #2aa89a;
      }
    }
  }
  &:nth-of-type(3n) {
    color: #d6338a;
    .historyTitle {
      background-color: #d6338a;
    }
    .historyBox {
      &::before {
        border: 6px solid #d6338a;
      }
    }
  }

  &::before {
    position: absolute;
    bottom: -50px;
    left: -23px;
    content: '';
    display: block;
    height: 4px;
    width: 120%;
    background-color: #fff;
  }
}

.year {
  position: relative;
  right: -80px;
  color: inherit;
  font-size: 36px;
  font-weight: 700;
  transform: rotate(-90deg);
}

.yearReverse {
  top: 150%;
}

.historyBox {
  position: relative;
  width: 400px;
  border-left: 4px solid #fff;
  padding: 10px 20px 10px;

  &::before {
    position: absolute;
    bottom: -70px;
    left: -23px;
    content: '';
    display: block;
    height: 30px;
    width: 30px;
    border: 6px solid white;
    background-color: #000;
    border-radius: 100%;
  }
}

.historyBoxReverse {
  top: 150%;
  &::before {
    position: absolute;
    bottom: 223px;
    left: -23px;
    content: '';
    display: block;
    height: 30px;
    width: 30px;
    border: 6px solid white;
    background-color: #000;
    border-radius: 100%;
  }
}

.historyTitle {
  letter-spacing: 0.3rem;

  color: #fff;
  margin-bottom: 10px;
  font-weight: 700;
  width: max-content;
  text-transform: uppercase;
  font-size: 24px;
}

.historyDescription {
  color: #fff;
}

.audioWrapper {
  padding-top: 20%;
  padding-right: 20px;
}

.listOfAudio {
  color: #fff;
  position: relative;
  z-index: 10;
  cursor: pointer;
  font-family: 'Geometria';
  text-align: left;
  font-size: 26px;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  background: linear-gradient(
    132deg,
    #d6338a -13.39%,
    rgba(214, 51, 138, 0.37) 83.67%
  );
}

.songs {
  margin-top: 10px;
  transition: 0.3s ease-in;
  height: 0;
  overflow: hidden;
  li {
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 10px;
    font-weight: 400;
    transition: font-weight 0.3s ease;

    &:hover {
      text-shadow: 0 0 1px white, 0 0px 1px white, 0 0px 1px white,
        0 0px 1px white, 0 0px 1px white;
    }
  }
}
