.header {
  position: fixed;
  z-index: 5000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 15px;
  width: 85vw;

  transition: padding .3s ease;
  will-change: padding;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: -1000px;
    right: -1000px;
    height: 100%;
  }

  &__black {
    &::before {
      background: rgba(black, .75);
    }

    a:first-child {
      svg {
        path {
          fill: white;
        }
      }
    }
  }

  &__white {
    &::before {
      background: rgba(white, .75);
    }

    a:first-child {
      svg {
        path {
          fill: black;
          transition: fill .3s ease;
        }
      }
    }
  }

  &__scroll {
    padding: 12px 0 8px;
  }
}

.header_content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  & > a {
    margin-right: calc(10vw - 30px);

    &:first-child {
      position: relative;
      top: 3px;
    }
  }
}

.logo {
  & > svg {
    width: 30px;
  }
}

.navLinks {
  display: flex;
  align-items: center;

  & > a {
    margin: 0 8px;

    font-size: 16px;
    font-weight: 400;

    color: #50535a;
    text-decoration: none;

    position: relative;
    z-index: 5;
    letter-spacing: -1px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: -2px;
      top: 3px;
      z-index: -1;
      display: block;
      width: 7px;
      height: 7px;
      background-color: #d6338a;

      transition: top 0.4s ease, width 0.4s ease, height 0.4s ease, left 0.4s ease;
      will-change: top, left, width, height;
    }

    &.pressed {
      &::before {
        top: 0;
        width: 2px;
        height: 20px;
        left: -10px;
      }
      &::after {
        top: 0;
        width: 2px;
        height: 20px;
        left: calc(100% + 8px);
      }
    }
  }

  &__white {
    a {
      color: #50535a;
    }
  }

  &__black {
    a {
      color: white;
    }
  }
}

// выезжающая панель бургера
.panel {
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 1000;
  height: 200px;
  width: 100%;

  padding: 10px 20px;
  box-sizing: border-box;

  transition: opacity 0.3s ease;
  margin-top: 100px;

  &__hide {
    opacity: 0;
    pointer-events: none;
  }
}

.overlay {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  left: 50%;
  top: calc(0% - 100px);
  transform: translateX(-50%);
  background-color: rgba(black, 0);
}

.panel_content {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);

  z-index: 5;
  width: 100vw;
  height: 100%;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 20px;
  box-sizing: border-box;

  transition: all 0.3s ease;

  display: flex;
  flex-direction: column;

  &__black {
    background-color: black;

    a {
      color: white;
    }
  }

  &__white {
    background-color: #fff;

    a {
      color: #50535a;
    }
  }

  &__hide {
    transform: scale(0.8, 1);
  }

  a {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-decoration: none;

    padding: 10px 10px;

    transition: color 0.3s ease;

    &:active {
      color: white;
      background-color: #4f4898;
    }
  }

  &.dark {
    background-color: black;

    a {
      color: white;
      text-align: right;

      &:hover {
        color: #d6338a;
      }
    }
  }
}
