.wrapper {
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}
