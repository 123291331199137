.wrapper {
  padding-top: 150px;

  @media (max-width: 1000px) {
    padding-top: 100px;
  }

  &.black {
    background-color: #000;
    & .order_name {
      color: #fff;
      & span {
        color: #fff;
      }
    }
    & .other_info {
      & span {
        color: #fff;
      }
    }
    & .order_item {
      span {
        color: #fff;
      }
    }
    & .button_group {
      a {
        color: #fff;
      }
    }
    & .summury {
      p {
        color: #fff;
      }
    }
    & .innerWrapper {
      @media (min-width: 900px) {
        padding-top: 155px;
      }
      padding-top: 50px;
    }
    & .emptyCard {
      color: #fff;
    }
    .title {
      color: white;
    }
  }
}

.order_item {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 0px;

  margin-bottom: 75px;

  .img_wrapper {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 237px;
    height: 158px;

    img {
      max-width: 237px;
      max-height: 158px;
    }
  }

  .order_info {
    grid-area: 1 / 2 / 2 / 5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    .order_name {
      display: flex;
      flex-direction: column;
    }

    .other_info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-right: 100px;

      & > div {
        display: flex;
        flex-direction: column;

        &:nth-of-type(1) {
          flex: 1 0 55%;
        }
        &:nth-of-type(2) {
          flex: 1 0 25%;
        }
        &:nth-of-type(3) {
          flex: 1 0 20%;
        }
      }
    }
  }

  .price_info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div.wrap {
      align-self: flex-end;
      margin-bottom: 18px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      //   padding-top: 40px;

      span {
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }

    grid-area: 1 / 5 / 2 / 6;

    svg {
      margin-left: 27px;
      cursor: pointer;
      padding: 10px 3px;

      transition: all 0.3s ease;

      circle {
        fill: #50535a;

        transition: all 0.3s ease;
      }

      &:hover {
        transform: scale(1.3);

        circle {
          fill: #d6358c;
        }
      }

      &:active {
        transform: scale(0.5);
        filter: blur(10px);

        circle {
          fill: #d6358c;
        }
      }
    }
  }

  .head_span {
    font-size: 20px;
    font-weight: 500;
    color: #50535a;
    margin-bottom: 5px;
  }

  span {
    font-size: 16px;
    font-weight: 300;
    color: #50535a;
  }
}
.order_item > *:nth-child(1) {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
}
.order_item > *:nth-child(2) {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
}
.order_item > *:nth-child(3) {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
}
.order_item > *:nth-child(4) {
  -ms-grid-row: 1;
  -ms-grid-column: 7;
}
.order_item > *:nth-child(5) {
  -ms-grid-row: 1;
  -ms-grid-column: 9;
}

.order_page_footer {
  display: flex;
  flex-direction: column;

  margin-bottom: 50px;

  .summury {
    display: flex;
    justify-content: flex-end;

    margin-bottom: 28px;

    p {
      font-size: 32px;
      font-weight: 500;
      color: #50535a;
    }
  }

  .button_group {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      position: relative;
      z-index: 0;

      font-family: 'Geometria', sans-serif;
      font-size: 24px;
      font-weight: 500;

      padding: 15px 38px;
      border: none;

      color: #ffffff;
      background-color: #4f4b98;

      cursor: pointer;

      // overflow: hidden;

      transition: all 0.3s ease;

      &::before {
        content: '';
        display: block;
        position: absolute;

        left: -30px;
        top: 31%;

        height: 20px;
        width: 5px;
        background-color: #f0e400;

        transform: skew(-25deg);
        transition: left 0.3s ease;
      }

      &:hover {
        &::before {
          left: 17px;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      font-size: 27px;
      font-weight: 500;
      color: #50535a;

      text-decoration: none;

      svg {
        margin-right: 10px;

        transition: transform 0.3s ease;
      }

      &:hover {
        svg {
          transform: translateX(-7px);
        }
      }
    }
  }
}

.input_number {
  display: flex;
  flex-direction: row !important;
  width: 100%;
  padding: 5px 0;
  position: relative;

  .value_filed {
    width: 100px !important;
    border: 1px solid #50535a;
    display: flex;
    justify-content: space-between;
    align-items: center;

    position: relative;
    height: 30px;
  }

  input {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  .quantity {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #d6358c;
  }

  button {
    position: absolute;
    top: 50%;
    z-index: 20;

    height: 25px;
    padding: 0 12px;
    border: none;
    background-color: #ffffff00;

    cursor: pointer;

    transform: translateY(-50%);
    transition: all 0.3s ease;

    @media (max-width: 900px) {
      padding: 0 15px;
    }

    &:hover {
      transform: translateY(-50%) scale(1.3);
    }

    &.plus {
      right: 5%;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        width: 19px;
        height: 2px;
        background-color: #aaa;
      }

      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        width: 2px;
        height: 19px;
        background-color: #aaa;
      }
    }

    &.minus {
      left: 5%;

      &::before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        width: 19px;
        height: 1px;
        background-color: #aaa;
      }
    }

    &:active {
      transform: translateY(-50%) scale(1);

      &::before,
      &::after {
        background-color: black;
      }
    }

    &.disabled {
      transform: translateY(-50%);

      &::before,
      &::after {
        background-color: #eee;
      }
    }
  }
}

// Mobile version

.mobile_order_item {
  position: relative;
  margin-top: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
  color: #50535a;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      display: block;
      width: 25px;
      height: 25px;
      filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.2));

      // box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);

      circle {
        fill: white;
      }

      path {
        stroke: #50535a;
      }
    }
  }

  span {
    font-size: 16px;
    font-weight: 300;
  }

  .head_span {
    font-weight: 500;
  }

  .img_wrapper {
    img {
      width: 100%;
    }
  }

  .order_info {
    font-size: 14px;

    .order_name {
      margin: 15px 0;

      span {
        font-size: 20px;
        font-weight: 500;
      }
    }

    .autor {
      margin: 10px 0 !important;
      // font-size: 14px;
    }
  }

  .price_info {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    align-items: center;

    margin-top: 20px;

    span {
      // margin-top: 7px;
      font-weight: 500;
      font-size: 26px;
    }

    .mobile_value_filed {
      min-width: 100px;
      border: 1px solid #50535a;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #d6358c;

      button {
        color: #888;

        &.disabled {
          // background-color: red;
          color: #eee;
        }
      }
    }

    button {
      background-color: #ffffff00;
      border: none;

      font-size: 27px;

      cursor: pointer;
      padding: 0 14px;
    }
  }
}

.mobile_order_page_footer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .summury {
    align-self: flex-end;
    display: flex;
    justify-content: flex-end;
    margin: 40px 0;

    p {
      text-align: right;
      color: #50535a;
      font-weight: 500;
      font-size: 26px;
    }
  }

  .button_group {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    margin-bottom: 30px;

    & > button {
      position: relative;

      font-family: 'Geometria', sans-serif;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 20px;

      padding: 10px 20px;
      border: none;

      color: #ffffff;
      text-transform: uppercase;
      background-color: #4f4b98;

      cursor: pointer;
    }
  }
}

.backLink,
.backLinkShowroom {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  // padding-top: 150px;

  margin: 0 auto;

  font-weight: 500;
  font-size: 27px;
  line-height: 44px;

  & a {
    color: #50535a;
    text-decoration: none;

    svg {
      position: relative;
      // top: 1px;
      transition: all 0.3s ease;
    }

    &:hover {
      svg {
        transform: translateX(-10px);
      }
    }
  }

  @media (max-width: 900px) {
    // margin-top: 10px;

    margin-top: 0;
    // padding-top: 25px;

    svg {
      rect {
        // fill: black;
        transition: fill 0.3s ease;
      }

      // &:hover {
      //   rect {
      //     fill: #ab1a68;
      //   }
      // }
      &:active {
        rect {
          fill: black;
        }
      }
    }
  }
}

.backLinkShowroom {
  a {
    color: #fff;
  }
}
