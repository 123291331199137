.cross {
  width: 59px;
  height: 59px;
  position: absolute;
  top: calc(50% - 354px);
  left: calc(50% + 213px);
  z-index: 10000;
  box-sizing: border-box;

  opacity: 0;

  animation-name: emergence_close-button;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;

  // opacity: 0.3;

  cursor: pointer;

  transition: all 0.3s ease;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 49%;
    left: -13px;
    width: 84px;
    height: 2px;
    background-color: #f0e400;
    // transform: translate(-4%, -90%);
  }
  &::before {
    transform: rotateZ(45deg);
  }
  &::after {
    transform: rotateZ(-45deg);
  }

  &:hover {
    transform: rotateZ(90deg);
  }

  @media (max-width: 900px) {
    position: fixed;
    top: calc(50% - 243px);
    left: calc(50% + 95px);
    &::before,
    &::after {
      width: 48px;
    }
  }
}

.card {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 11000;

  transform: translate(-50%, -50%);
  // 323px * 1.5 (Ширина карточки со scale 1.5)
  width: 481px;
  // 434px * 1.5 (Высота карточки со scale 1.5)
  height: 651px;

  @media (max-width: 900px) {
    width: 214px;
    height: 287px;
    transform: translate(-50%, calc(-50% - 70px));
  }
}


.cardText {
  position: fixed;
  top: calc(50% + 120px);
  left: calc(50% + 161px);

  height: auto;

  color: red;
  font-size: 80px;

  opacity: 0;

  animation-name: emergence_close-button;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.8s;

  & .cardTitle::after {
    content: '';
    margin-top: 2px;
    display: block;
    height: 1px;
    background-color: #fff;
    width: 0;
    transition: width 0.3s ease;
  }
  &:hover {
    & .cardTitle::after {
      width: 100%;
    }
  }
  @media (max-width: 900px) {
    left: 0;
    top: 310px;
  }
}

.cardTitle {
  width: min-content;
  border-bottom: 0;
  margin-bottom: 8px;
  display: block;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;

  font-family: 'Geometria';
  font-style: normal;
  font-weight: 700;
  font-size: 41.4866px;
  line-height: 100.3%;

  transition: border-bottom 0.3s ease;

  @media (max-width: 900px) {
    font-size: 30px;
  }
}

.cardDescription {
  text-transform: none;
  width: 272px;
  color: #fff;
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 136.3%;

  @media (max-width: 900px) {
    font-size: 14px;
  }
}

.cardButton {
  display: inline;
  text-decoration: none;
  color: white;
  font-weight: 700;
  font-size: 19px;
  line-height: 1;
  padding: 6px 20px 8px;
  text-align: center;
  text-transform: capitalize;

  width: auto;

  background: linear-gradient(270deg, #d6358c 0, #4f4b98 50%, #d6358c 100%);
  background-size: 400% 400%;

  -webkit-animation: gradient 20s linear infinite;
  animation: gradient 20s linear infinite;

  &__mobile {
    font-size: 16px;

    position: fixed;
    // top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
  }
}


.overlay {
  position: fixed;
  z-index: -1;
  left: 0;top: 0;right: 0;bottom: 0;
  background-color: rgba(black, .35);
}

@keyframes emergence_close-button {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes gradient {
  0% {
    background-position: -400% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 400% 50%;
  }
}
@keyframes gradient {
  0% {
    background-position: -400% 50%;
  }
  50% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -400% 50%;
  }
}
