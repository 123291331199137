.footer {
  background-color: #000000;
  height: 100%;

  .inner {
    display: grid;
    grid-template-columns: 180px 1fr;
    column-gap: 80px;

    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 50px 0 78px;

    & > img {
      grid-area: 2/1/3/2;
      height: 100%;
    }
  }

  .head_of_footer {
    grid-area: 1/2/2/3;

    padding-bottom: 10px;
    border-bottom: 0.4px solid #ffffff;

    font-size: 25px;
    font-weight: 500;
    color: #ffffff;
  }

  .footer_info {
    grid-area: 2/2/3/3;
    margin-bottom: -5px;
    height: 100%;
  }

  .middle_of_header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    height: 100%;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    height: 100%;

    margin: 0;
    padding: 0;

    &:first-child {
      margin-right: 30px;
    }

    li {
      display: flex;
      align-items: center;

      font-size: 18px;
      color: #ffffff;

      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 18px;
        color: #ffffff;
        text-decoration: none;
      }

      img {
        margin-right: 16px;
      }
    }
  }

  .contacts_item {
    a {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        height: 1px;
        width: 0;

        transition: width 0.3s ease;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }
    }
  }

  .docs {
    padding-top: 1px;
    height: 100%;

    li {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        text-align: right;
      }
    }
  }

  .doc_links {
    a {
      img {
        transition: transform 0.3s ease;
      }
    }

    a {
      &:hover {
        img {
          transform: translateX(-10px);
        }
      }
    }
  }

  .bottom_of_footer {
    display: flex;
    margin-top: 12px;
    color: #ffffff;

    p {
      font-size: 18px;
      font-weight: 300;
      color: #ffffff;
      margin-right: 40px;

      span {
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  .socials {
    margin-top: auto;
    margin-bottom: 17px !important;

    img {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .social {
    margin-left: 20px;

    svg {
      width: 25px;
      height: 20px;

      path {
        transition: fill 0.2s ease;
      }

      &:hover {
        path {
          fill: #4f4b98;
        }
      }
    }
  }
}

// Мобильная версия

.mobile_footer {
  background-color: #000000;

  display: flex;
  flex-direction: column;

  padding-top: 20px;

  position: relative;

  .footer_info {
    width: 100%;
    // position: relative;
  }

  .head_of_footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 15px;

    border-bottom: 1px solid #ffffff;

    & > img {
      position: absolute;
      bottom: 19%;
      right: 10%;
      width: 135px;
      // opacity: 0.15;
      z-index: 0;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      //   margin-left: 30px;
      //   margin-bottom: 5px;
    }
  }

  .middle_of_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    position: relative;
    z-index: 100;
  }

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    margin: 0;
    padding: 0;

    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    li {
      display: flex;
      align-items: center;

      font-size: 15px;
      font-weight: 400;
      color: #ffffff;

      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 15px;
        font-weight: 400;
        color: #ffffff;
        text-decoration: none;


      }

      img {
        margin-right: 16px;
      }
    }

    .address {
      //   background-color: red;
      img {
        align-self: flex-start;
        margin-top: 1px;
      }

      address {
        font-style: normal;
      }
    }
  }

  .contacts_item {
    a {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #fff;
        height: 1px;
        width: 0;

        transition: width 0.3s ease;
      }

      &:hover {
        &::after {
          width: 100%;
        }
      }

      &:active {
        &::after {
          width: 100%;
        }
      }
    }

    &.phone {
      flex-wrap: wrap;
      a:nth-child(4) {
        width: 100%;
        margin-left: 32px;
        margin-top: 10px;
        margin-right: 60px;

      }
    }
  }

  .bottom_of_footer {
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    color: #ffffff;
    position: relative;
    z-index: 100;

    p {
      font-size: 14px;
      font-weight: 300;
      color: #ffffff;
      margin-bottom: 5px;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }

  .socials {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
  }

  .social {
    margin-left: 25px;

    svg {
      width: 25px;
      height: 22px;

      &:active {
        path {
          fill: #4f4b98;
        }
      }
    }
  }
}