.background {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: background-color 0.3s ease;
  // background-color: rgba(0, 0, 0, 0);
  pointer-events: none;

  &.active {
    display: block;
    z-index: 8000;
    pointer-events: all;
    // background-color: rgba(0, 0, 0, 0.35);
  }
}

// .headerBackground {
//   width: 100%;
//   background-color: #000;
//   opacity: 0.65;
//   height: 165px;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 1;
//   @media (max-width: 900px) {
//     height: 80px;
//   }
// }

