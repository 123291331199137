.page {
  min-height: calc(100vh - 5px);
  height: calc(100vh - 5px);
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  width: 250px;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.img {
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.imgCaption {
  text-align: center;
  font-size: 9px;
  font-weight: 500;
}

.subTitle {
  margin-top: 50px;
  margin-bottom: 26px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.input {
  width: 100%;
  height: 30px;
  margin-bottom: 13px;
  background-color: black;
  outline: none;
  border: 1px solid white;
  color: white;

  &:focus::placeholder {
    color: transparent;
  }

  &::placeholder {
    color: white;
    font-size: 12px;
    text-align: center;
  }
}

.box {
  width: 100%;
  height: 142px;
  font-size: 12px;
  margin-bottom: 22px;
  border: 1px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  padding: 10px;
  text-align: center;
}

.remember {
  margin-top: 10px;
  margin-bottom: 30px;
  align-self: baseline;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;

  & .customCheckbox:after {
    left: 8px;
    bottom: 5px;
    width: 4px;
    height: 12px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &input:checked ~ .customCheckbox {
    background-color: black;
  }

  & input:checked ~ .customCheckbox:after {
    display: block;
  }
}

.checkbox {
  width: 26px;
  height: 26px;
  accent-color: black;
  visibility: hidden;
}

.customCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  border: 1px solid white;
  background-color: black;

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
}

.button {
  cursor: pointer;
  font-weight: 700;
  padding: 7px;
  background-color: #e7e453;
  border: none;

  &:active {
    opacity: 0.8;
  }
}
