.archive {
  margin-bottom: 66px;
  margin-top: 150px;

  &__mobile {
    margin-top: 100px;
  }
}

.title {
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;
  color: #50535A;
  margin-bottom: 60px;

  &__mobile {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 30px;
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 100px;
  row-gap: 60px;
  // max-width: 100%;
  // box-sizing: border-box;

  &__mobile {
    grid-template-columns: 1fr;
  }

  a {
    text-decoration: none;

    button {
      display: none;
    }
  }
}

.exhibition {
  width: 100%;

  display: grid;
  grid-template-columns: 100px 1fr;
  grid-auto-rows: minmax(35px, min-content) auto;
  column-gap: 16px;
  row-gap: 15px;

  height: 130px;

  &:hover {
    span {
      opacity: 1;
    }
  }

  h2 {
    grid-area: 1/2/2/3;

    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #50535A;
  }

  p {
    grid-area: 2/2/3/3;

    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    text-align: justify;
    color: #50535A;
  }

  &__mobile {
    grid-template-columns: 1fr;
    column-gap: unset;
    height: auto;

    h2 {
      grid-area: unset;
      font-size: 16px;
      line-height: 20px;
    }

    p {
      grid-area: unset;
    }
  }
}

.image {
  grid-area: 1/1/3/2;
  height: 140px;
  position: relative;
  overflow: hidden;




  span {
    position: absolute;
    top: 0; left: 0;
    z-index: 10;

    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;

    display: block;

    padding: 5px;
    opacity: 0;
    transition: opacity .3s ease;


    &::before {
      content:'';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 160%;
      height: 60px;

      opacity: .8;

      background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    }

    &:after {
      content: '';
      position: absolute;
      right: -8px;
      top: 50%;
      z-index: 1;

      height: 8px;
      width: 6px;
      transform: translateY(-50%);
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.4552 0.986328L4.60783 4.13886L1.4552 7.29149' stroke='white' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  img {
    height: 130px;
    display: block;
    width: 100%;

    // padding: ;
  }

  &__mobile {
    grid-area: 3/1/4/2;
    height: 95px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;

      // position: relative;
      z-index: 0;
    }

    span {
      top: unset;
      left: unset;
      bottom: 12px;
      right: 12px;
      z-index: 100;
      opacity: 1;

      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      padding: 5px 18px;
      background-color: #4F4B98;

      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
  }
}

