.item {
  padding: 20px 20px 40px 20px;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  &:hover {
    background-color: #d6338a;

    .title {
      text-decoration: underline;
      color: white;
    }

    .date {
      color: white;
    }
  }

  @media (max-width: 768px) {
    padding: 8px 8px 17px 8px;
    background-color: #d6338a;
    margin-right: 6px;
  }
}

.image {
  margin-bottom: auto;
  margin-bottom: 20px;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #fff;
    aspect-ratio: 0.73;
  }
}

.title {
  font-size: 20px;
  margin-bottom: 10px;

  @media(max-width: 768px) {
    font-size: 16px;
    text-decoration: underline;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
  }
}

.date {
  font-size: 14px;
  font-weight: 400;

  @media(max-width: 768px) {
    font-size: 12px;
    color: white;
  }
}
