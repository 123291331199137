.picture_wrapper {
  position: relative;
  // margin-bottom: 20px;
  // filter: drop-shadow(4px 3px 4px rgba(0, 0, 0, 0.25));
  // box-shadow: 4px 3px 4px rgba(0, 0, 0, 0.25);

  // border: 8px solid #d4d8da;

  width: 100%;
  // aspect-ratio: 0.67/1;

  transition: all 0.3s ease;
  overflow: hidden;

  & + .picture_info {
    // position: absolute;
    // left: 0;
    // bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    box-sizing: border-box;

    background-color: #fff;

    padding: 20px 0;
    pointer-events: none;

    z-index: 0;

    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      color: #000;
    }

    svg {
      align-self: flex-end;
    }
  }

  &[data-status="hide"] {
    opacity: 0;
  }
  &[data-status="show"] {
    opacity: 1;
  }

  &:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),
      7px 7px 5px 0px rgba(0, 0, 0, 0.4);
  }
}

.mobile_picture_wrapper {
  position: relative;
  margin-bottom: 20px;

  border: 8px solid #d4d8da;
  // filter: drop-shadow(4px 3px 4px rgba(0, 0, 0, 0.25));
  box-shadow: 4px 3px 4px rgba(0, 0, 0, 0.25);

  .picture_info {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;

    box-sizing: border-box;

    background-color: #00000099 !important;

    padding: 4px 22px 19px 19px;

    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 17px;
      color: #ffffff;
    }

    svg {
      align-self: flex-end;
    }
  }
}

.image {
  width: 100%;
  aspect-ratio: 1/0.67;
  display: block;

  object-fit: contain;
  background: none;
}

.fakeImage {
  position: absolute;
  top: -50%;
  left: 0%;

  z-index: -1;

  filter: blur(10px);
  object-fit: cover;
  width: 200%;
  height: 200%;
}
