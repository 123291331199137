.card {
  position: relative;
  width: 323px;
  height: 434px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.3s ease;
  background-size: cover;
  top: 0;

  box-shadow: 0 0 0px 0px yellow;
  outline: 0rem solid yellow;
  outline-offset: -2px;


  &.active {
    transform: scale(1.5);
    z-index: 5;
    @media (max-width: 900px) {
      transform: scale(1.75);
      top: -70px;
    }
  }

  &:hover {
    // outline: 2px solid yellow;
    box-shadow: 0 0 10px 2px yellow;
    outline: .2rem solid yellow;
  }

  @media (max-width: 900px) {
    width: 122px;
    height: 164px;
  }
}
