.content {
  margin-right: 20px;
}

.firstList {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-size: 24px;
  li {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  svg {
    height: 30px;
    width: 30px;
  }
  margin-bottom: 30px;
}

.documentation {
  margin-bottom: 30px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 30px;
  display: flex;
}

.text {
  text-align: center;
  border: 2px solid white;
  padding: 0px 20px;
  font-size: 40px;
  border-radius: 10px;
  height: max-content;
}

.secondList {
  margin-left: 50px;
  li {
    cursor: pointer;
    margin-bottom: 20px;
    text-decoration: underline;
  }
}

.questWrapper {
  display: flex;
  gap: 50px;
  margin-bottom: 30px;
}

.quest {
  font-size: 40px;
}

.questButton {
  cursor: pointer;
  color: white;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.2rem;
  border: none;
  padding: 20px;
  border-radius: 10px;
  background: linear-gradient(
    127deg,
    #d6338a 21.18%,
    rgba(214, 51, 138, 0.37) 106.57%
  );
}
@media (max-width: 1350px) {
  .firstList {
    font-size: 20px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .text {
    font-size: 20px;
  }
  .quest {
    font-size: 20px;
  }
  .questWrapper {
    flex-direction: column;
    gap: 20px;
  }
  .questButton {
    font-size: 16px;
  }
}
@media (max-width: 980px) {
  .documentation {
    gap: 30px;
    flex-direction: column;
  }
  .secondList {
    margin-left: 0px;
  }
  .firstList {
    font-size: 16px;
  }
}

@media (max-width: 980px) {
}
