.container {
  display: none;
  align-items: center;
  height: 100%;
  margin-left: auto;

  a {
    margin-left: 27px;
    color: #ffffff;
    text-decoration: none;
    font-size: 10px;

    position: relative;

    display: block;
    height: 100%;

    .counter {
      position: absolute;
      padding: 1px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      background-color: #d6338a;

      bottom: -2px;
      right: -4px;
    }

    svg {
      height: 25px;
      width: 24px;
      path {
        fill: #50535a;
      }
    }
  }

  &__gallery {
    display: flex;
  }
}
