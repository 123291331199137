.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 50px;
  // display: flex;
  // align-items: center;
  flex: 1 0 auto;
}

.leftColumn {
}
.image {
  margin: 0 auto 20px;
  width: 600px;
  img {
    width: 600px;
    object-fit: cover;
    height: 350px;
    border-radius: 20px;
  }
}
.rightColumn {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.lists {
  display: flex;
  row-gap: 20px;
  padding: 20px;
}

.list {
  text-transform: uppercase;
  width: 50%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  flex-wrap: wrap;
  list-style-type: none;
  font-size: 16px;
  li {
    display: flex;
    align-items: center;
    gap: 10px;
    svg {
      height: 16px;
      width: 20px;
    }
  }
}

.count,
.countPurple {
  text-transform: uppercase;
  font-size: 40px;
  display: flex;
  gap: 20px;
  align-items: center;
}
.countPurple {
  color: #d6338a;
}
.number,
.numberPurple {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid white;
  padding: 10px 25px;
}
.numberPurple {
  border: 2px solid #d6338a;
}
@media (max-width: 1350px) {
  .count,
  .countPurple {
    font-size: 20px;
  }
  .number,
  .numberPurple {
    padding: 10px 17px;
  }
  .list {
    font-size: 14px;
  }
  .image {
    width: 500px;
    img {
      width: 500px;
    }
  }
}
@media (max-width: 1200px) {
  .image {
    width: 300px;
    height: 200px;
    img {
      width: 300px;
      height: 200px;
    }
  }
}
@media (max-width: 880px) {
  .content {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 50px;
    // display: flex;
    // align-items: center;
    flex: 1 0 auto;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .lists {
    flex-direction: column;
  }
  .list {
    width: 100%;
  }
  .image {
    width: 250px;
    img {
      width: 250px;
    }
  }
}
