.wrapper {
  background-color: #000;
  color: #fff;
}

.product {
  display: grid;
  grid-template-columns: 478px 1fr;
  column-gap: 50px;
  min-height: 345px;

  margin-bottom: 140px;
  margin-top: 43px;

  @media (max-width: 1200px) {
    display: block;
    margin-bottom: 19px;
    padding-top: 0px;
  }
}

.productImages {
  width: 100%;

  @media (max-width: 1200px) {
    margin-bottom: 28px;
  }
}

.productWrapper {
  display: flex;
  flex-direction: column;

  height: 100%;
  min-height: 345px;
}

.productTitle {
  max-width: 624px;
  margin-bottom: 11px;

  font-family: 'Geometria';
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 44px;

  @media (max-width: 1200px) {
    margin-bottom: 9px;

    font-size: 18px;
    line-height: 23px;
  }
}

.backArrow {
  display: flex;
  justify-content: flex-end;

  color: #fff;
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 44px;

  padding-top: 150px;

  & a {
    color: #fff;
    text-decoration: none;

    svg {
      transition: transform 0.3s ease;

      @media (max-width: 1000px) {
        rect {
          transition: fill 0.3s ease;
          fill:#ab1a68;
        }
        path {
          stroke: white;
        }
      }
    }

    &:hover {
      svg {
        transform: translateX(-10px);
      }
    }
  }



  @media (max-width: 1200px) {
    line-height: 0;
    padding-top: 100px;
  }


}

.shop {
  margin-bottom: 26px;
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;

  @media (max-width: 1200px) {
    margin-bottom: 17px;

    font-size: 15px;
    line-height: 19px;
  }
}



.productDescription {
  @media (max-width: 1200px) {
    margin-bottom: 6px;
    padding-left: 0;
  }
}

.productDescriptionText {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 25px;

  a {
    color: #F0E400;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1200px) {
    font-size: 15px;
    line-height: 19px;
  }
}

.price {
  margin-top: auto;

  display: flex;
  // flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.productCost {
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 44px;

  @media (max-width: 1200px) {


    margin-left: 0;
    margin-bottom: 22px;

    font-size: 20px;
    line-height: 25px;
  }
}

.productButton {
  width: 204px;
  height: 62px;
  margin-left: 50px;
  border: 0;
  background-color: #4f4b98;
  color: #fff;

  font-family: 'Geometria';
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 31px;

  cursor: pointer;

  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    opacity: 1;

    left: -30px;
    top: 31%;

    height: 20px;
    width: 5px;
    background-color: #f0e400;

    transform: skew(-25deg);
    transition: all 0.3s ease;

    @media (max-width: 1200px) {
      height: 12px;
      width: 4px;
    }
  }

  &:hover {
    &::before {
      left: 17px;
    }
  }

  &:disabled {
    outline-offset: -2px;
    outline: 2px solid #4f4b98;

    background-color: transparent;
    cursor: auto;

    &::before {
      opacity: 0;
    }
  }

  @media (max-width: 1200px) {
    width: 122px;
    height: 37px;

    margin-bottom: 22px;

    font-size: 15px;
    line-height: 19px;
  }
}
