.wrapper {
  width: 100vw;
  height: 100vh;

  &__mobile {
    display: flex;
    flex-flow: column-reverse;
    height: 92vh;
  }
}

.master {
  width: 100%;
  height: 100%;
  background-color: black;

  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;

  &__mobile {
    height: 70%;
  }
}

.section {
  grid-area: 1/1/2/2;

  position: relative;

  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;

  &:nth-of-type(1) {
    z-index: 1;

    .section_info {
      background-color: transparent;
      padding-left: 0;
      width: 400px;
      transform: translateY(38vh);
      opacity: 1;

      svg {
        margin-right: 20px;
        width: 80px;
        min-width: 80px;
        height: 78px;
      }

      a {
        margin-right: 20px;
      }

      p {
        span {
          &:last-child {
            display: inline-block;
            max-width: 300px;
          }
        }
      }
    }
  }

  &:nth-of-type(2) {
    clip-path: polygon(59% 0%, 82% 0%, 50% 100%, 27% 100%);
    transition: clip-path 0.3s ease, filter .3s ease;
    -webkit-transition: clip-path 0.3s ease, filter .3s ease;
    z-index: 20;

    filter: grayscale(1);

    background-image: url('../../../../assets/img/test_pictures/background_master_3.jpg');

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100vh;
      background-image: url('../../../../assets/img/gradient_svg/large_black_white.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top left;
      opacity: 1;
      // backdrop-filter: grayscale(1);

      transition: opacity .3s ease;
      will-change: opacity;
    }

    &:hover {
      filter: grayscale(0);
      &::after {
        opacity: 0;
      }
    }

    &.section__active {
      clip-path: polygon(0% 0%, 82% 0%, 50% 100%, -23% 100%);
      filter: grayscale(0);

      &::after {
        opacity: 0;
      }

      .section_info {
        transform: translateY(38vh);
        opacity: 1;
      }
    }

    &.section__previous {
      clip-path: polygon(0% 0%, 4% 0%, 4% 100%, 0% 100%);
      filter: grayscale(1);

      &::after {
        opacity: 0;
      }
    }

    .section_info {
      width: 300px;

      &::before {
        content: '';
        position: absolute;
        left: -10px;
        top: 0;
        width: 10px;
        height: 100%;
        background-color: #F0E400;
      }
    }
  }

  &:nth-of-type(3) {
    clip-path: polygon(82% 0%, 105% 0, 73% 100%, 50% 100%);
    transition: clip-path 0.3s ease, filter .3s ease;
    -webkit-transition: clip-path .3s ease, filter .3s ease;
    background-image: url('../../../../assets/img/test_pictures/background_master_1.jpg');
    z-index: 30;

    filter: grayscale(1);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 20%;
      z-index: 0;

      width: 100%;
      height: 100%;
      background-image: url('../../../../assets/img/gradient_svg/large_black_white.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top left;
      opacity: 1;
      // backdrop-filter: grayscale(1);

      transition: opacity .3s ease;

      will-change: opacity;
    }

    &:hover {
      filter: grayscale(0);
      &::after {
        opacity: 0;
      }
    }

    &.section__active {
      clip-path: polygon(0% 0%, 110% 0, 87% 100%, -23% 100%);
      filter: grayscale(0);

      &::after {
        opacity: 0;
      }

      .section_info {
        transform: translateY(38vh);
        opacity: 1;
      }
    }

    &.section__previous {
      clip-path: polygon(0% 0%, 8% 0%, 8% 100%, 0% 100%);
      filter: grayscale(1);

      &::after {
        opacity: 0;

      }
    }

    .section_info {
      &::before {
        content: '';
        position: absolute;
        left: -10px;
        top: 0;
        width: 10px;
        height: 100%;
        background-color: #2CA99B;
      }
    }
  }

  &:nth-of-type(4) {
    clip-path: polygon(105% 0%, 132% 0%, 100% 100%, 73% 100%);
    transition: clip-path 0.3s ease, filter .3s ease;
    -webkit-transition: clip-path .3s ease, filter .3s ease;
    background-image: url('../../../../assets/img/test_pictures/background_master_2.jpg');
    z-index: 40;

    filter: grayscale(1);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 44%;
      z-index: 0;

      width: 100%;
      height: 100%;
      background-image: url('../../../../assets/img/gradient_svg/large_black_white_2.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: top left;
      opacity: 1;
      // backdrop-filter: grayscale(1);

      transition: opacity .3s ease;

      will-change: opacity;
    }

    &:hover {
      filter: grayscale(0);
      &::after {
        opacity: 0;
      }
    }

    &.section__active {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, -27% 100%);
      filter: grayscale(0);

      .section_info {
        transform: translateY(38vh);
        opacity: 1;
      }

      &::after {
        opacity: 0;
      }
    }

    .section_info {
      &::before {
        content: '';
        position: absolute;
        left: -10px;
        top: 0;
        width: 10px;
        height: 100%;
        background-color: #D6358C;
      }
    }
  }

  &__mobile {
    grid-area: 1/1/-1/-1;

    &::after {
      display: none;
    }

    &:nth-of-type(1) {
      clip-path: polygon(0% 0%, 110% 0%, 100% 100%, 0% 100%);

      .section_info {
        width: auto;
        height: auto;
        transform: translateY(30vh);
        padding: 0;

        transform: translate(-50%, 26vh);
        width: 75vw;
        justify-content: flex-start;

        svg {
          margin-right: 15px;
          // width: 60px;
          // max-width: 50px;
          width: auto;
          height: 58px;
          position: relative;
          margin-left: -7px;

          path {
            position: absolute;
          }
        }
        p {
          margin-top: 10px;
        }
      }
    }

    &:nth-of-type(2) {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);

      &.section__active {
        clip-path: polygon(0% 0%, 110% 0%, 100% 100%, 0% 100%);

        .section_info {
          transform: translate(-50%, 26vh);
          width: 70vw;
        }
      }
      &.section__previous {
        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
      }
    }

    &:nth-of-type(3) {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);

      &.section__active {
        clip-path: polygon(0% 0%, 110% 0%, 100% 100%, 0% 100%);

        .section_info {
          transform: translate(-50%, 26vh);
          width: 70vw;
        }
      }

      &.section__previous {
        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
      }
    }

    &:nth-of-type(4) {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);

      &.section__active {
        clip-path: polygon(0% 0%, 110% 0%, 100% 100%, 0% 100%);

        .section_info {
          transform: translate(-50%, 26vh);
          width: 70vw;
        }
      }

      &.section__previous {
        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
      }
    }
  }
}

.section_info {
  position: absolute;
  top: 50%;
  transform: translateY(10vh);
  z-index: 1;

  width: 300px;
  padding: 20px;
  background-color: rgba(black, .75);
  opacity: 0;

  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: opacity .4s ease, transform .4s ease;
  -webkit-transition: transform .4s ease, opacity .4s ease;

  will-change: opacity, transform;

  div {
    display: flex;
    align-items: center;
  }

  h3 {
    font-weight: 700;
    font-size: 38px;
    line-height: 44px;
    text-transform: uppercase;
  }

  a {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: white;
    text-decoration: none;
    padding: 5px 18px;
    background-color: #4F4B98;
    width: min-content;
    margin-top: 15px;
  }

  &__mobile {
    left: 50%;
    font-size: 14px;
    line-height: 16px;
    text-align: left;

    transform: translate(-50%, 10vh);

    width: auto;
    height: auto;
    width: 70vw;

    h3 {
      font-size: 26px;
      line-height: 33px;
    }

    a {
      align-self: flex-end;
    }
  }
}


// ======== Кнопки =========


.control {
  height: 30%;
  position: relative;
  z-index: 50;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
}

.button {
  position: relative;
  border: none;
  outline: none;

  margin: 0;
  padding: 0;

  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: white;
  text-align-last: left;

  background-color: black;

  img {
    position: relative;
    z-index: 2;
    opacity: 1;

    width: 100%;
    height: 100%;
    object-fit: cover;

    transition: opacity 0.3s ease;
    -webkit-transition: opacity .3s ease;
  }

  span {
    position: absolute;
    left: 50px;
    top: 50%;
    transform: translateY(-50%);

    z-index: 100;
    transition: padding 0.3s ease;
    -webkit-transition: padding .3s ease;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 20px;
    z-index: 6;

    width: 9px;
    height: 21px;

    transform: translateY(-50%) skew(-30deg);
    transition: transform 0.3s ease, width 0.3s ease;
    will-change: transform, width;
  }

  &:nth-of-type(1) {
    &::before {
      background-color: #F0E400;
    }
  }
  &:nth-of-type(2) {
    &::before {
      background-color: #2AA89A;
    }
  }
  &:nth-of-type(3) {
    &::before {
      background-color: #D6358C;
    }
  }

  &__pressed {
    span {
      padding-left: 20px;
    }
    img {
      opacity: 0;
    }
    &::before {
      width: 21px;
      transform: translateY(-50%) skew(0deg);
    }
  }
}
