.backArrow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: #fff;
  font-weight: 500;
  font-size: 27px;
  margin-bottom: 20px;
  color: #fff;
  text-decoration: none;

  svg {
    transition: transform 0.3s ease;

    @media (max-width: 1000px) {
      rect {
        transition: fill 0.3s ease;
        fill: #ab1a68;
      }
      path {
        stroke: white;
      }
    }
  }

  &:hover {
    svg {
      transform: translateX(-10px);
    }
  }
}

.rezidents {
  background-color: #000;
  color: #fff;
  padding: 50px 0px;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px 0px;
  }

  .title {
    margin: 0 auto 20px;
    width: max-content;
    border-radius: 15px;
    padding: 15px;
    border: 2px solid #fff;
    font-weight: 700;
    font-size: 46px;

    @media (max-width: 768px) {
      font-size: 18px;
    }
  }
  .categories {
    font-weight: 300;
    display: flex;
    width: 80%;
    margin: 0 auto 20px;
    font-size: 20px;
    justify-content: space-evenly;
    gap: 20px;
    @media (max-width: 1330px) {
      justify-content: space-between;
      flex-wrap: wrap;
      font-size: 16px;
    }
  }
  .category {
    cursor: pointer;

    &:hover {
      color: #f0e400;
    }
  }

  .categoryActive {
    color: #f0e400;
    font-weight: 700;
  }

  .cards {
    width: 80%;
    margin: 0 auto;
    // display: flex;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 40px 40px 60px;
    border-top: 2px solid #f0e400;
    // border-bottom: 2px solid #f0e400;
    overflow-x: auto;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 438px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .cardsOpen {
    display: block;
  }
}

.card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  gap: 100px;
}

.first {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.logo {
  width: 100%;
  max-width: 400px;
  border-radius: 20px;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.link {
  text-decoration: underline;
  font-size: 16px;
}
.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    width: 30px;
    height: 30px;
  }
}
.phone {
  font-size: 20px;
}
.buttonCard {
  text-align: center;
  border: none;
  padding: 10px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 20px;
  background: linear-gradient(
    127deg,
    #4f4c93 21.18%,
    rgba(79, 76, 147, 0) 106.57%
  );
}

.second {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.secondTitle {
  margin: 0 auto 20px;
  width: max-content;
  border-radius: 15px;
  padding: 15px;
  border: 2px solid #fff;
  font-weight: 700;
  font-size: 46px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
}
.secondTitle {
  max-width: 700px;
}

.cardTitle {
}

.cardDesc {
  flex: 1 1 auto;
}

.third {
  width: 300px;
}
.slider {
  width: 100%;
  max-width: 500px;
  border-radius: 20px;
  aspect-ratio: 1/1;
  object-fit: cover;
}

@media (max-width: 1350px) {
  .card {
    gap: 50px;
  }
}

@media (max-width: 1150px) {
  .card {
    grid-template-columns: 1fr;
  }
  .second {
    order: 0;
  }
  .first {
    order: 1;
  }
  .third {
    order: 2;
  }
  .slider {
    margin: 0 auto;
    width: 70%;
  }
}
