.blot {
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background-color: transparent;

  width: 40px;
  height: 40px;

  padding: 0;
  margin-right: 20px;

  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;

    path {
      fill: #50535a;
      transition: fill 0.3s ease;
    }
  }

  &:hover {
    // animation: pump;
  }
}

.buttons_item {
  &:nth-child(4n-1) {
    .blot {
      &:hover {
        svg {
          path {
            fill: #4f4898;
          }
        }
      }
      &.active {
        svg {
          path {
            fill: #4f4898;
          }
        }
      }
    }
  }
  &:nth-child(4n-3) {
    .blot {
      &:hover {
        svg {
          path {
            fill: #db1980;
          }
        }
      }
      &.active {
        svg {
          path {
            fill: #db1980;
          }
        }
      }
    }
  }
  &:nth-child(4n-2) {
    .blot {
      &:hover {
        svg {
          path {
            fill: #38c3b4;
          }
        }
      }
      &.active {
        svg {
          path {
            fill: #38c3b4;
          }
        }
      }
    }
  }
  &:nth-child(4n-4) {
    .blot {
      &:hover {
        svg {
          path {
            fill: #f0e400;
          }
        }
      }
      &.active {
        svg {
          path {
            fill: #f0e400;
          }
        }
      }
    }
  }
}

@keyframes pump {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
