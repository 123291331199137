.confirm_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    .answer_wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
            margin-bottom: 26px;
        }

        p {
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: #50535A;

            margin-bottom: 20px;
        }

        button {
            padding: 7px 30px;
            border: none;
            margin-bottom: 50px;

            cursor: pointer;

            background-color: #4F4B98;

            text-decoration: none;
            font-family: 'Geometria', sans-serif;
            font-size: 25px;
            font-weight: 500;
            color: #ffffff;
        }
    }
}