.card {
  display: grid;
  grid-template-columns: repeat(2, min-content);
  grid-auto-rows: min-content;
  row-gap: 7px;

  background-color: transparent;
  border: none;

  width: 100%;
  cursor: pointer;
  padding: 0;

  position: relative;

  &__active {
    // outline: 2px solid yellow;
    .img_shadow {
      position: absolute;
      left: -10px;
      top: -10px;
      z-index: 0;

      width: 100%;
      height: 100%;
      background-color: #f0e400;
    }
  }

  &__mobile {
    grid-template-columns: 1fr minmax(35px, min-content);
    row-gap: 10px;
    column-gap: 16px;
  }

  // &:focus {
  // }
}

.inner_image {
  grid-area: 1/1/2/2;
  width: 160px;
  height: 160px;
  position: relative;

  &__mobile {
    width: 100%;
    height: 100%;
  }
}

.img_shadow {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;

  width: 100%;
  height: 100%;
  background-color: yellow;

  transition: all 0.3s ease;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  display: block;
  background-color: black;

  box-shadow: -4px -4px 4px rgba(0, 0, 0, 0.25);

  &__mobile {
    grid-area: 1/1/2/2;
    justify-self: center;
    width: 100%;
    height: 100%;
  }
}

.dots {
  position: absolute;
  display: inline-flex;
  padding: 5px;

  bottom: 10px;
  left: 50%;
  transform: translateX(-45%);

  background-color: rgba(black, 0.4);

  // background-color: #f0e400;
  width: auto;
  // height: 10px;
}

.dot_button {
  display: block;
  // width: 3px;
  height: 10px;
  border-radius: 0;
  transform: skew(-30deg);
  background-color: transparent;
  border: 1px solid #f0e400;

  padding: 3px 3px;
  margin: 0 7px;

  &__active {
    background-color: #f0e400;
  }
}

.name {
  grid-area: 2/1/3/2;

  font-family: 'Geometria';
  font-weight: 500;
  font-size: 15px;
  line-height: 19px;
  color: white;

  text-align: left;

  &__mobile {
    grid-area: 2/1/3/2;

    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;
  }
}

.social_list {
  grid-area: 1/2/2/3;

  a {
    display: inline-block;
    padding: 0 5px 5px;
    margin-bottom: 5px;

    svg {
      width: 18px;
      height: 18px;

      path {
        transition: fill 0.25s ease;
      }
    }

    &:hover {
      svg {
        path {
          fill: #4f4b98;
        }
      }
    }
  }

  &__mobile {
    grid-area: 1/2/2/3;
    justify-self: flex-start;

    a {
      margin-right: 0;
      margin-bottom: 10px;

      svg {
        width: 25px;
        height: 20px;
      }
    }
  }
}
