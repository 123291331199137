.text_container {
  margin-top: 200px;

  p,
  span,
  a,
  li {
    font-family: "Geometria", sans-serif !important;
    margin-bottom: 10px;
    line-height: 1.3;
  }
}
