.path_info {
  grid-area: 2/1/3/3;

  .buttons {
    width: 50%;
    margin-bottom: 65px;

    display: flex;
    flex-wrap: wrap;

    & > button {
      background-color: transparent;
      border: none;
      padding: 4px 23px 6px;

      border: 1px solid #d6358c;
      border-radius: 4px;

      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.3s ease;
      white-space: nowrap;
      margin-bottom: 15px;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: white;
      }

      &:not(:last-of-type) {
        margin-right: 20px;
      }

      &:hover {
        background-color: #d6358c;

        span {
          text-shadow: 0 0 0.5px white, 0 0 0.5px white, 0 0 0.5px white,
            0 0 0.5px white;
        }
      }

      &.active {
        background-color: #d6358c;

        span {
          text-shadow: 0 0 0.5px white, 0 0 0.5px white, 0 0 0.5px white,
            0 0 0.5px white;
        }
      }
    }
  }

  &__mobile {
    grid-area: 3/1/4/2;

    .buttons {
      width: 100%;
      margin-bottom: 15px;

      flex-wrap: wrap;
    }
  }
}
