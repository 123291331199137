.clusterFooter {
  background-color: #000;
  color: #fff;
  padding-left: 150px;
  padding-bottom: 100px;
  padding-right: 150px;

  @media (max-width: 768px) {
    padding-left: 65px;
    padding-bottom: 50px;
    padding-right: 0;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-size: 30px;
  margin-bottom: 20px;

  svg {
    width: 40px;
    height: 40px;
  }
}

.addressWrapper2 {
  display: flex;
  justify-content: space-between;
  border-bottom: 4px solid white;
  padding-bottom: 50px;
  margin-bottom: 20px;
}
.addressWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.address {
  width: max-content;
  font-size: 26px;
  padding: 0 10px;
  background-color: rgba(214, 51, 138, 1);
}

.links {
  display: flex;
  flex-direction: column;
}
.link {
  text-decoration: none;
  color: #fff;
}

.contact {
  width: max-content;
  font-size: 26px;
  padding: 0 10px;
  background-color: rgba(44, 169, 155, 1);
}

.smallText {
  font-size: 12px;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;

  svg {
    margin-left: 10px;
    width: 30px;
    height: 30px;
  }
}

.images {
  margin-top: 20px;
  width: max-content;
  display: flex;
  gap: 20px;
  border: 1px solid rgba(42, 168, 154, 0.77);
  border-radius: 20px;
  height: 200px;
  img {
    position: relative;
    left: -20px;
    top: -20px;
    border-radius: 20px;
    width: 300px;
    height: 200px;
    object-fit: cover;
    box-shadow: 2px 2px 9px 0px #fff;
  }
}

@media (max-width: 1600px) {
  .addressWrapper2 {
    flex-direction: column;
  }
}

@media (max-width: 1050px) {
  .images {
    height: 150px;
    img {
      width: 200px;
      height: 150px;
    }
  }
}

@media (max-width: 768px) {
  .images {
    height: unset;
    flex-direction: column;

    img {
      width: 300px;
      height: 200px;
    }
  }
  .footer {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}
