.container {
  display: none;
  align-items: center;
  // justify-content: space-between;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  // margin-top: 10px;
  // padding: 15px 0 10px;

  .links {
    display: flex;
    align-items: center;
    height: 100%;
  }

  a {
    // margin-right: 27px;
    color: #ffffff;
    text-decoration: none;

    font-size: 10px;

    position: relative;
    display: block;
    height: 100%;

    svg {
      height: 21px;
      width: 24px;
      path {
        // fill: ;
      }
    }

    .counter {
      position: absolute;
      padding: 1px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      background-color: #d6338a;

      bottom: -2px;
      right: -4px;
    }
  }

  &__gallery {
    display: flex;
  }
}
