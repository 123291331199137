.card {
  position: relative;
  // padding: 40px;
  // border: 2px solid #d9d9d9;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  // &:hover {
  //   border: 2px solid #f0e400;

  //   .cardButton {
  //     background-color: #f0e400;
  //   }
  // }

  img {
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  &Title {
    // text-align: center;
    margin: 20px 0 0;
    font-size: 20px;
  }

  .social {
    display: flex;
    align-items: center;
    gap: 20px;

    svg {
      width: 30px;
    }
  }

  &Button {
    cursor: pointer;
    font-family: 'Geometria';
    font-size: 20px;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 700;
    border: none;
    background-color: #d9d9d9;
    position: absolute;
    bottom: -20px;
  }
}
