.cluster {
  height: 100vh;
  background: #000;
  color: #fff;
  font-weight: 400;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1480px) {
    height: 100vh;
  }
  @media (max-width: 1400px) {
    flex-direction: column;
    height: 114vh;
  }
  @media (max-width: 1245px) {
    height: 116vh;
  }
  @media (max-width: 1150px) {
    height: 120vh;
  }
  @media (max-width: 1050px) {
    height: 100vh;
    // padding-bottom: 250px;
  }
  @media (max-height: 934px) {
    padding-bottom: 150px;
  }
}

.container {
  padding-left: 150px;
  width: 100%;
  padding-top: 70px;

  @media (max-width: 768px) {
    padding-left: 65px;
  }
}

.header {
  font-weight: 500;
  font-size: 158px;
  letter-spacing: 120px;

  @media (max-width: 980px) {
    font-size: 120px;
    letter-spacing: 90px;
  }
  @media (max-width: 768px) {
    font-size: 70px;
    letter-spacing: 30px;
  }

  @media (min-width: 2400px) {
    font-size: 250px;
    letter-spacing: 170px;
  }

  span {
    display: block;

    &:last-of-type {
      font-size: 98px;

      @media (max-width: 980px) {
        font-size: 88px;
      }

      @media (max-width: 768px) {
        font-size: 42px;
        line-height: 0.7em;
      }

      @media (min-width: 2400px) {
        font-size: 180px;
      }
    }
  }
}

.about {
  width: 50%;

  @media (max-width: 1050px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
}

.subHeader {
  margin-top: 20px;
  font-weight: 300;
  font-size: 26px;

  span {
    margin-left: 20px;

    @media (min-width: 2400px) {
      margin-left: 40px;
    }
  }
  @media (min-width: 2400px) {
    font-size: 52px;
  }
}

.aboutText {
  width: 90%;
  margin-top: 80px;
  font-weight: 200;
  font-size: 26px;
  line-height: 1.8;

  @media (min-width: 2400px) {
    font-size: 40px;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
    font-size: 18px;
    padding-left: 0;
    margin-bottom: 30px;
  }

  @media (max-width: 450px) and (max-height: 700px) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.containerImages {
  position: relative;
  right: -200px;

  @media (min-width: 2400px) {
    right: 100px;
  }
  @media (max-width: 1700px) {
    right: 100px;
  }
  @media (max-width: 1480px) {
  }
  @media (max-width: 1400px) {
    top: -800px;
    right: -1020px;
  }
  @media (max-width: 1050px) {
    top: 18px;
    right: -301px;
  }
  @media (max-width: 820px) {
    top: -60px;
    right: -301px;
  }
  @media (max-width: 768px) {
    top: 20px;
    right: -301px;
  }
  @media (max-width: 601px) {
    top: -120px;
    right: -200px;
  }
  @media (max-width: 578px) {
    top: -120px;
    right: -200px;
  }
}

.images {
  display: flex;
  position: relative;
  left: -150px;
  @media (max-width: 768px) {
    top: -70px;
  }
}

.firstWrapper {
  position: relative;
  pointer-events: none;

  &:hover {
    z-index: 100;
  }
}
.firstWrapperInner {
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: -7px;
  left: 150px;
  width: 200px;
  height: 100%;
  transform: skew(-31deg);
  transition: box-shadow 0.3s linear;

  &:hover {
    border: 2px solid #f0e400;
    box-shadow: 5px 1px 5px 0px #f0e400;
  }
  @media (min-width: 2400px) {
    width: 324px;
    left: 238px;
  }
}
.thirdWrapperInner {
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  top: -5px;
  left: 169px;
  width: 225px;
  height: 100%;
  transform: skew(-31deg);
  transition: box-shadow 0.3s linear;
  &:hover {
    border: 2px solid #f0e400;
    box-shadow: 5px 1px 5px 0px #f0e400;
  }

  @media (min-width: 2400px) {
    width: 369px;
    left: 270px;
  }
}
.image {
  position: relative;
  top: 0;
  left: 0;

  @media (max-width: 768px) {
    top: 0;
    left: 20px;
  }

  p {
    position: relative;
    top: 285px;
    left: -140px;
    transform: rotate(-60deg);

    @media (min-width: 2400px) {
      font-size: 24px;
      left: -214px;
      top: 447px;
    }

    @media (max-width: 768px) {
      font-size: 12px;
      top: 168px;
      left: -81px;
    }

    &.thirdTitleImage {
      top: -235px;
      left: 118px;

      @media (max-width: 768px) {
        font-size: 12px;
        top: -147px;
        left: 75px;
      }
      @media (min-width: 2400px) {
        font-size: 24px;
        top: -373px;
        left: 188px;
      }
    }
  }

  img {
    width: 500px;
    // clip-path: polygon(55% 0, 100% 0%, 45% 100%, 0% 100%);

    @media (min-width: 2400px) {
      width: 800px;
    }

    @media (max-width: 768px) {
      width: 300px;
    }
  }

  &:nth-of-type(2) {
    top: 200px;
    left: -470px;

    @media (min-width: 2400px) {
      top: 250px;
      left: -680px;
    }

    @media (max-width: 768px) {
      top: 92px;
      left: -257px;
    }
  }
  &:nth-of-type(3) {
    top: 100px;
    left: -734px;

    @media (min-width: 2400px) {
      top: 110px;
      left: -1115px;
    }

    @media (max-width: 768px) {
      top: 55px;
      left: -428px;
    }
  }
}

.buttons {
  margin-top: 80px;
  background-color: #000;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: 980px) {
    flex-direction: column;
  }

  @media (max-width: 768px) {
    margin-top: 50px;
    gap: 20px;
  }
  @media (max-width: 450px) and (max-height: 700px) {
    margin-top: 0;
  }

  button {
    position: relative;
    z-index: 10;
    cursor: pointer;
    font-family: 'Geometria';
    text-align: left;
    font-size: 16px;
    width: 250px;
    border: none;
    border-radius: 10px;
    padding: 16px 20px;
    font-weight: 200;

    @media (min-width: 2400px) {
      width: 500px;
      font-size: 32px;
    }

    &:nth-of-type(1) {
      background-color: #4f4c93;
      color: #fff;
      z-index: 15;
    }
    &.whiteButton {
      background-color: #d9d9d9;
      color: #000;
      z-index: 13;
    }
    &.purpleButton {
      background-color: #d6338a;
      z-index: 11;
    }
  }

  .button1Wrapper,
  .button2Wrapper,
  .button3Wrapper {
    position: relative;
  }

  .button3Wrapper {
    right: -500px;
    @media (min-width: 2400px) {
      top: -102px;
      right: -120%;
    }
    @media (max-width: 1480px) {
      right: -400px;
    }
    @media (max-width: 1400px) {
      right: 0;
    }
    @media (max-width: 1050px) {
      position: static;
    }
    @media (max-width: 578px) {
    }
  }

  .dropDown,
  .dropDown1,
  .dropDown2,
  .dropDown3 {
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 30px;
    background-color: #fff;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    width: 250px;
    box-shadow: 10px 10px 36px -5px rgba(0, 0, 0, 0.45);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;

    input {
      margin: 5px 20px 5px;
      padding: 10px;
      background-color: #d9d9d9;
      border: none;

      &:first-of-type {
        margin-top: 30px;
      }
      &::placeholder {
        font-family: 'Geometria';
        text-align: center;
        color: #000;
      }
    }

    p {
      margin-top: 5px;
      text-align: center;
      font-size: 10px;
      color: #000;
      margin-bottom: 10px;
    }
  }

  .dropDown1 {
    z-index: 14;
  }

  .dropDown2 {
    z-index: 12;
  }

  .dropDown3 {
    z-index: 10;
  }
  .sendButton {
    margin: 0 auto;
    width: 210px;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    background-color: #d6338a !important;
  }
}

.submitMessage {
  height: 100%;
  background-color: rgba(44, 169, 155, 0.92);
  padding: 20px;
  padding-top: 40px;
  p {
    color: #fff !important;
    font-size: 16px !important;
    text-align: center;
    border: 1px solid #fff;
    padding: 5px;
  }

  button.close {
    text-align: center;
    background-color: transparent;
    width: 100%;
  }
}
