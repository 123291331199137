.cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // flex-wrap: wrap;
  column-gap: 3rem;
  margin-bottom: 60px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 18px;
  }
}
.card {
  // flex: 1 0 auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;



  @media (max-width: 900px) {
    
    align-items: center;
    // flex: 0 0 48%;
    margin-bottom: 26px;
  }
}

.shopImage {
  // width: 228px;
  width: 100%;
  aspect-ratio: 1/1;
  // height: 228px;
  border: 1px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;

  & img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 900px) {
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
  }
}
.shopTitle {
  color: #fff;
  text-decoration: none;
  font-family: 'Geometria';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100.3%;
  word-wrap: break-word;

  @media (max-width: 900px) {
    font-size: 14px;
  }
}

.cardInfo {
  text-decoration: none;

  // width: 228px;
  cursor: pointer;
  @media (max-width: 900px) {
    width: 100%;
    min-height: 100%;
  }
}
