.path {
  width: 100%;
  display: grid;
  grid-template-columns: 52% 40%;

  // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  // grid-template-columns: minmax(60%, 100%) minmax(0, 20%);
  // grid-template-columns: repeat(auto-fill, minmax(min-content, 1fr));
  // column-gap: 50px;
  justify-content: space-between;

  &__mobile {
    grid-template-columns: 1fr;
  }
}

.info {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-auto-rows: min-content;
  column-gap: 13px;
  row-gap: 10px;

  // max-height: 400px;
  // overflow-y: auto;

  position: relative;

  // &::after {
  //   content: '';
  //   position: absolute;

  // }

  .time {
    grid-area: 1/1/3/2;
    align-self: flex-start;
    // justify-self: center;

    font-weight: 700;
    font-size: 71.639px;
    line-height: 70px;
    color: white;

    &__mobile {
      font-size: 45px;
      line-height: 57px;
    }
  }

  .event_title {
    grid-area: 1/2/2/3;

    font-weight: 700;
    font-size: 25.8462px;
    line-height: 33px;
    color: #f0e400;

    &__mobile {
      font-size: 25px;
      line-height: 31px;
      margin-bottom: 10px;
    }
  }

  .location {
    grid-area: 2/2/3/3;

    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    color: rgba(#ffffff, 0.75);

    &::before {
      content: url("data:image/svg+xml,%3Csvg width='9' height='12.5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.6 12c1.2-2.4 3.2-6.4 3.2-8A4 4 0 0 0 .7 4c0 1.6 2 5.6 3.2 7.8a1 1 0 0 0 1.7 0Zm-.9-5.7a2.2 2.2 0 1 0 0-4.5 2.2 2.2 0 0 0 0 4.5Z' fill='%23bfbfbf'/%3E%3C/svg%3E");
      position: relative;
      display: inline-block;
      padding-right: 11px;
    }

    &__mobile {
      margin-bottom: 15px;
    }
  }

  .desc {
    grid-area: 3/1/4/3;

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      text-align: left;
      color: white;
    }

    li,
    span,
    address {
      color: white !important;
      background-color: transparent !important;
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 23px !important;
      text-align: left !important;
    }

    li {
      margin-left: 20px;
    }

    a {
      color: #d6358c;
      text-decoration: none;
      font-weight: 400;
    }

    &__mobile {
      margin-bottom: 45px;

      p {
        font-size: 17px;
        line-height: 21px;
      }
      li,
      span,
      address {
        color: white !important;
        background-color: transparent !important;
        font-weight: 400 !important;
        font-size: 17px !important;
        line-height: 21px !important;
        text-align: left !important;
      }

      li {
        margin-left: 20px;
      }
    }
  }

  &__mobile {
    display: flex;
    flex-direction: column;
    row-gap: 0;
    column-gap: 0;
  }
}

.recomend {
  border: 1px solid #13ac9b;
  padding: 60px 30px;

  position: relative;
  right: 0;
  top: -112px;

  &::before {
    content: attr(data-recomend);
    position: absolute;
    left: 33px;
    top: -14px;

    padding: 0 12px;

    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: white;
    background-color: black;
  }

  &__mobile {
    right: unset;
    top: 0;
    padding: 25px 15px;

    display: flex;
    flex-direction: column-reverse;

    margin-left: 0;

    &::before {
      left: 50%;
      transform: translateX(-50%);

      white-space: nowrap;
      font-size: 17px;
      line-height: 21px;
    }
  }
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  box-sizing: border-box;

  &__mobile {
    grid-template-columns: 1fr;
  }
}

.recomend_text {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
  color: #bfbfbf;

  margin-top: 15px;
  position: relative;
  top: -110px;

  &__mobile {
    position: static;
    font-size: 17px;
    line-height: 21px;
    color: white;

    margin-top: 0;
    margin-bottom: 15px;
  }
}

.cluster {
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  padding: 40px;
  background: linear-gradient(
    226deg,
    rgba(79, 76, 147, 0.9) 13.5%,
    rgba(79, 76, 147, 0) 132.03%
  );
  display: flex;
  gap: 30px;
  .day {
    font-size: 60px;
    font-weight: 700;
  }
  .month {
    font-weight: 700;
    font-size: 20px;
  }
  .dayofWeek {
    font-size: 20px;
    width: max-content;
    padding-bottom: 5px;
    border-bottom: 1px solid gray;
  }
  .start {
    margin-top: 10px;
    font-weight: 300;
  }
  .descWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
  }

  .title {
    font-weight: 700;
    font-size: 20px;
  }
  .place {
    display: flex;
    gap: 10px;
    font-weight: 300;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 120%;
    width: 30%;
    object-fit: cover;
    filter: grayscale(1);
    transform: scale(1.5) rotate(8deg);
  }
  .button {
    cursor: pointer;
    width: max-content;
    border: none;
    color: #fff;
    font-weight: 500;
    border-radius: 10px;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 10px;
    background: linear-gradient(
      132deg,
      #d6338a -13.39%,
      rgba(214, 51, 138, 0.37) 83.67%
    );
  }
}

.cluster:nth-of-type(3n) {
  background: linear-gradient(
    226deg,
    rgba(79, 76, 147, 0.9) 13.5%,
    rgba(79, 76, 147, 0) 132.03%
  );

  .button {
    background: linear-gradient(
      132deg,
      #d6338a -13.39%,
      rgba(214, 51, 138, 0.37) 83.67%
    );
  }
}
.cluster:nth-of-type(3n + 1) {
  background: linear-gradient(
    226deg,
    rgba(42, 168, 154, 0.9) 13.5%,
    rgba(79, 76, 147, 0) 132.03%
  );
  .button {
    background: linear-gradient(
      132deg,
      rgba(79, 76, 147, 1) -13.39%,
      rgba(79, 76, 147, 0.37) 83.67%
    );
  }
}
.cluster:nth-of-type(3n + 2) {
  background: linear-gradient(
    226deg,
    rgba(214, 51, 138, 0.9) 13.5%,
    rgba(79, 76, 147, 0) 132.03%
  );
  .button {
    background: linear-gradient(
      132deg,
      rgba(42, 168, 154, 1) -13.39%,
      rgba(42, 168, 154, 0.37) 83.67%
    );
  }
}
// .button:nth-of-type(3n) {
//   background: linear-gradient(
//     226deg,
//     rgba(79, 76, 147, 0.9) 13.5%,
//     rgba(79, 76, 147, 0) 132.03%
//   );
// }
// .button:nth-of-type(3n + 1) {
//   background: linear-gradient(
//     226deg,
//     rgba(42, 168, 154, 0.9) 13.5%,
//     rgba(79, 76, 147, 0) 132.03%
//   );
// }
// .button:nth-of-type(3n + 2) {
//   background: linear-gradient(
//     226deg,
//     rgba(214, 51, 138, 0.9) 13.5%,
//     rgba(79, 76, 147, 0) 132.03%
//   ) !important;
// }
.buttons {
  display: flex;
  gap: 20px;
}
.buttonPink {
  cursor: pointer;
  width: max-content;
  border: none;
  color: #fff;
  font-weight: 500;
  border-radius: 10px;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 10px;
  background: linear-gradient(
    132deg,
    #d6338a -13.39%,
    rgba(214, 51, 138, 0.37) 83.67%
  );
}
.clusterOpen {
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}
.openWrapper {
  padding: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 50px;
    padding: 20px;
  }
}
.aboutEvent {
  display: flex;
  flex-direction: column;
  gap: 100px;
}
.titleBig {
  font-size: 50px;
  font-weight: 700;
}
.placeBig {
  display: flex;
  gap: 20px;
  align-items: center;
  font-size: 30px;
  svg {
    width: 30px;
    height: 30px;
  }
}
.timeWrapper2 {
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  border-radius: 20px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.56);
  padding: 20px;
  color: #50535a;
}
.timee {
  display: flex;
  align-items: center;
  gap: 20px;
}
.dayofWeek2,
.month2 {
  font-size: 30px;
}
.day2 {
  font-size: 80px;
}
.month2 {
  font-weight: 700;
}
.start2 {
  font-size: 30px;
}
.time2 {
  font-size: 30px;
}
.openEvent {
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin-bottom: 100px;

  @media (max-width: 920px) {
    flex-direction: column;
  }
}
.left {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 920px) {
    width: 100%;
  }
}
.eventTitle {
  font-size: 40px;
  font-weight: 700;
}
.eventText {
  padding-left: 40px;
  font-size: 20px;
}
.right {
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 920px) {
    width: 100%;
  }
}
.eventDesc {
  font-size: 26px;
  font-weight: 700;
  padding: 10px;
  border-radius: 10px;
  background: linear-gradient(
    90deg,
    #4f4c93 -1.08%,
    rgba(79, 76, 147, 0) 92.81%
  );
}
.eventPlace {
  font-size: 20px;
}
.org {
  display: flex;
  gap: 20px;
  font-size: 20px;

  img {
    width: 100px;
  }
}
@media (max-width: 1150px) {
  .cluster {
    img {
      width: 20%;
    }
  }
  .aboutEvent {
    gap: 50px;
  }
}
@media (max-width: 930px) {
  .cluster {
    display: flex;
    flex-wrap: wrap;
    .day {
      width: max-content;
    }
    .descWrapper {
      margin-right: 100px;
      width: 60%;
    }
    img {
      width: 30%;
    }
  }
  .dayofWeek2,
  .month2 {
    font-size: 20px;
  }
  .day2 {
    font-size: 50px;
  }
  .start2 {
    font-size: 20px;
  }
  .time2 {
    font-size: 20px;
  }
  .titleBig {
    font-size: 30px;
  }
  .placeBig {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 20px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .buttons {
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .cluster {
    padding: 20px;
  }
}
