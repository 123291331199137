.profile {
  color: white;
  display: grid;
  grid-template-columns: 3fr 2fr;
  column-gap: 35px;

  &__mobile {
    display: flex;
    flex-direction: column;
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  column-gap: 20px;
  row-gap: 20px;

  height: auto;

  &__mobile {
    display: block;
    margin-bottom: 30px;
  }
}

.actions {
  font-family: 'Geometria';
  font-weight: 500;
  font-size: 25px;
  line-height: 31px;
  color: white;

  background-color: transparent;
  border: none;

  margin-bottom: 20px;
}

.have_actions {
  .not_act {
    display: none;
  }
}

.not_actions {
  .not_act {
    font-size: 18px;
    line-height: 22px;
    color: #999;
  }
}

.desc {
  overflow: hidden;
}

.desc_text {
  white-space: pre-wrap;

  opacity: 0;
}

.fade {
  white-space: pre-wrap;

  &.enter {
    opacity: 0;
    transform: scale(0.95);

    &-active {
      opacity: 1;
      transform: translateX(0);
      transition: all 0.3s ease;
    }
  }

  &.exit {
    opacity: 1;

    &-active {
      opacity: 0;
      transform: translateX(500px) scale(0.95);
      transition: all 0.3s ease;
    }
  }
}
