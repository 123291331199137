.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 50%;
}

.content {
  display: flex;
  align-items: center;
  flex: 1 0 auto;
  margin-right: 20px;
  padding-right: 20px;
}
.textwithSVG {
  display: grid;
  gap: 100px;
  grid-template-columns: 120px 1fr;
  text-transform: uppercase;
  font-size: 30px;
  img {
    width: 120px;
    height: 120px;
  }
}

.rightColumn {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-left: auto;
  align-items: center;
  svg {
    height: 30px;
    width: 30px;
  }
}
.textRez {
  width: 100%;
  text-align: center;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 10px;
  font-size: 30px;
}

@media (max-width: 1350px) {
  .textRez,
  .textwithSVG {
    font-size: 20px;
  }
}
@media (max-width: 980px) {
  .rightColumn,
  .leftColumn {
    width: 100%;
    margin-left: unset;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .textwithSVG {
    gap: 20px;
  }
  .textRez,
  .textwithSVG {
    font-size: 14px;
  }
  .content {
    flex-direction: column;
    align-items: baseline;
  }
}
