.volume {
  margin: 0;
  border-radius: 9999px;
  height: 0.5rem;
  background-color: #374151;
  appearance: none;
  cursor: pointer;
  width: 80px;
  accent-color: #d6338a;
}
